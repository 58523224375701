import apiBase from "../../apis/api";
import {
  POST_RESERVATION,
  DECLINE_RESERVATION,
  PIN_UNLOCKED,
  UPDATE_RESERVATION,
} from "../constants";
import { makeHeader } from "./base";
import {
  feedbackSuccessful,
  feedbackFailed,
  feedbackIsFetching,
} from "./feedback";

export const postReservation = (reservation) => {
  return async (dispatch) => {
    try {
      dispatch(feedbackIsFetching());
      const headers = makeHeader();
      const response = await apiBase.post("/reservation", reservation, headers);
      if (response.status !== 200 && response.status !== 201) throw new Error();
      dispatch(feedbackSuccessful("Room reserved successfully!"));
      dispatch({ type: POST_RESERVATION, payload: response.data });
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Creating reservation failed!"
        )
      );
    }
  };
};

export const updateReservation = (reservation) => {
    return async (dispatch) => {
      try {
        dispatch(feedbackIsFetching());
        const headers = makeHeader();
        const response = await apiBase.put("/reservation/update", reservation, headers);
        if (response.status !== 200 && response.status !== 201) throw new Error();
        dispatch(feedbackSuccessful("Reservation dates updated successfully!"));
        dispatch({ type: UPDATE_RESERVATION, payload: response.data });
      } catch (e) {
        dispatch(
          feedbackFailed(
            e.response.data.message || "Reservation updating failed!"
          )
        );
      }
    };
  };

export const cancelReservation = (id) => {
  return async (dispatch) => {
    try {
      dispatch(feedbackIsFetching());
      const headers = makeHeader();
      const response = await apiBase.delete(`/reservation/${id}`, headers);
      if (response.status !== 200) throw new Error();
      dispatch(feedbackSuccessful("Reservation successfully removed!"));
      dispatch({ type: DECLINE_RESERVATION, payload: id });
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Removing reservation process failed!"
        )
      );
    }
  };
};

export const unlockPin = (id) => {
  return async (dispatch) => {
    try {
      const headers = makeHeader();
      const response = await apiBase.put(
        `/reservation/resend/${id}`,
        null,
        headers
      );
      if (response.status !== 200) throw new Error();
      dispatch(feedbackSuccessful("Reservation PIN unlocked!"));
      dispatch({ type: PIN_UNLOCKED, payload: response.data.id });
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Unlocking reservation PIN failed!"
        )
      );
    }
  };
};
