import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Container from './Container/Container';
import LoadingPage from './LoadingPage/LoadingPage';

class AppLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            minimumDurationPassed: props.minimumLoadingTime <= 0
        }
    }

    componentDidMount() {
        if (this.props.minimumLoadingTime) {
            setTimeout(() => this.setState({minimumDurationPassed: true}), this.props.minimumLoadingTime);
        }
    }

    render() {
        return (
            <Fragment>
                { this.props.isLoggedIn !== null && this.state.minimumDurationPassed === true ?
                    <Container />
                : 
                    <LoadingPage />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, null)(AppLoader);