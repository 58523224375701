import { LOGIN, LOGOUT, USER_NOT_FOUND, USERS_DATA, ALL_USER, USER_ADDED, USER_DELTED } from '../constants';
import { parseJwt } from '../../util/util';

const INITIAL_STATE = {
    isLoggedIn: null,
    user: null,
    currentUser: null,
    allUsers: [],
    tokenLifetime: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGOUT:
            return { user: null, isLoggedIn: false };
        case LOGIN:
            const user = parseJwt(action.payload);
            return { 
                user: {
                    username: user.sub,
                    authority: user.authorities
                },
                isLoggedIn: true,
                tokenLifetime: user.exp 
            };
        case USERS_DATA:
            return{
                ...state,
                currentUser: action.payload
            }    
        case USER_NOT_FOUND:
            return { ...state, isLoggedIn: false };
        case ALL_USER:
            return {...state, allUsers: action.payload}    
        case USER_ADDED:
            return {...state, allUsers: [...state.allUsers, action.payload]}    
        case USER_DELTED:
            return {...state, allUsers: state.allUsers.filter(user => user.id !== action.payload.id)}    
        default:
            return state;
    }
}