import apiBase from '../../apis/api';
import { HOTELS, GET_HOTEL, ADD_NEW_HOTEL, STAFF_ADDED, STAFF_REMOVED, LOGO_FETCHED, LOGO_UPLOADED, HOTEL_UPDATED } from '../constants';
import { makeHeader } from './base';
import { feedbackSuccessful, feedbackFailed, feedbackIsFetching, feedbackResetStatus } from './feedback';
import FormData from 'form-data'

export const getHotelsByRole = () => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get('/accommodation/role', headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: HOTELS, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const getHotelsByRoleWithLogo = () => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get('/accommodation/role/logo', headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackResetStatus());
            dispatch({ type: HOTELS, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const getHotelById = (id) => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get(`/accommodation/${id}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: GET_HOTEL, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const addNewAccommodation = newAccommodation => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.post(`/accommodation`, newAccommodation, headers);
            if (response.status !== 200 && response.status !== 201) throw new Error();
            dispatch(feedbackSuccessful('New Accommodation added successfully!'));
            dispatch({ type: ADD_NEW_HOTEL, payload: response.data });
        } catch (e) {
            console.log(e.message);
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new accommodation!'));
        }
    }
}

export const addAcoomodationStaff = staff => {
    return async dispatch => {
        try{
            const headers = makeHeader();
            const respose = await apiBase.put('/accommodation/staff',staff,headers);
            if(respose.status !== 200 || respose.data === null) throw new Error();
            dispatch(feedbackSuccessful('New staff added successfully!'));
            dispatch({type: STAFF_ADDED, payload: respose.data})
        } catch(e){
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new staff!'));
        }
    }
}

export const removeAccomodationStaff = staff => {
    return async dispatch => {
        try{
            const headers = makeHeader();
            const respose = await apiBase.put('/accommodation/staff/delete',staff,headers);
            if(respose.status !== 200 || respose.data === null) throw new Error();
            dispatch(feedbackSuccessful('Staff removed successfully!'));
            dispatch({type: STAFF_REMOVED, payload: respose.data})
        } catch(e){
            dispatch(feedbackFailed(e.response.data.message || 'Error while removing staff!'));
        }
    }
}

export const getHotelLogo = hotel => {
    return async dispatch => {
        try{
            const headers = makeHeader();
            const respose = await apiBase.get(`/accommodation/${hotel}/logo`, headers);
            if(respose.status !== 200 || respose.data === null) throw new Error();
            dispatch({type: LOGO_FETCHED, payload: respose.data})
        } catch(e){
            dispatch(feedbackFailed(e.response.data.message || 'Error while getting logo!'));
        }
    } 
}

export const uploadLogo = (logo,hotel,crop) => {
    return async dispatch => {
        let data = new FormData();
        data.append('file', logo, logo.fileName);
        try{
            const headers = makeHeader();
            const respose = await apiBase.put(`/accommodation/${hotel}/logo/${crop.x}/${crop.y}/${crop.w}/${crop.h}`, data, headers);
            if(respose.status !== 200 || respose.data === null) throw new Error();
            dispatch({type: LOGO_UPLOADED, payload: respose.data})
            dispatch(feedbackSuccessful('Logo uploaded!'));

        } catch(e){
            dispatch(feedbackFailed(e.response.data.message || 'Error while uploading logo!'));
        }
    } 
}

export const updateAccommodation = updateObj => {
    return async dispatch => {
        try{
            const headers = makeHeader();
            const respose = await apiBase.put(`/accommodation/${updateObj.id}`,updateObj,headers);
            if(respose.status !== 200 || respose.data === null) throw new Error();
            dispatch(feedbackSuccessful('Accommodation updated successfully!'));
            dispatch({type: HOTEL_UPDATED, payload: respose.data})
        } catch(e){
            dispatch(feedbackFailed(e.response.data.message || 'Error while removing staff!'));
        }
    }
}