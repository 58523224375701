import React, { Component, Fragment } from 'react';
import { fetchAcceptedDevices, fetchPendingDevices, fetchRejectedDevices, newPendingDevicesRegistered,
deviceHeartbeat, rejectDevice } from '../../store/actions/devices';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import DeviceDetails from './DeviceDetails';
import { BASE_URL } from '../../store/constants';
import SockJsClient from 'react-stomp';
import { acceptDevice, resetUpdatedDevice } from '../../store/actions/devices';
import CloseIcon from '../../assets/svg/close.svg';

const INIT_STATE = {
    active: 'accepted',
    selectedDevice: null,
    autoReRender: true,
    showModal: false,
    configuringDevice: null,
    selectedHotel: null,
    modalPage: null,
    serverHost: "",
    modbusHost: "",
    modbusPort: "",
    accomodation: "",
    roomIdRegister: "",
    floorIdRegister: "",
    cardTypeRegister: "",
    accessLevelRegisterLo: "",
    accessLevelRegisterHi: "",
    writeCommandRegister: "",
    dispenserStatusRegister: "",
    yearRegister: "",
    monthRegister: "",
    dayRegister: "",
    dispenserStatusErrorRegister: ""

}


Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '2%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

class Devices extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    componentDidMount() {
        this.props.fetchAcceptedDevices();
        if (this.props.hotels) {
            this.setState({
                selectedHotel: this.props.hotels[0].id
            })
        }
        this._isMounted = true;
        this.myInterval = setInterval(() => {
            this.setState({ autoReRender: true });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
        this._isMounted = false;
    }

    componentDidUpdate(){
        if(this.props.deviceConfigUpdated !== null){
            this.props.resetUpdatedDevice();
            this.setState({
                selectedDevice: this.props.updatedDevice
            })
        }
    }
    

    onLinkClicked = link => {
        switch (link) {
            case 'accepted':
                this.props.fetchAcceptedDevices();
                break;
            case 'pending':
                this.props.fetchPendingDevices();
                break;
            case 'rejected':
                this.props.fetchRejectedDevices();
                break;
            default:
                break;
        }
        this.setState({active: link});
    }

    onSelectDevice = device => {
        if (this.state.selectedDevice && this.state.selectedDevice.id === device.id){
            this.setState({selectedDevice: null});
        } else {
            this.setState({selectedDevice: device});
        }
    }

    onNewConfiguration = device => {
        this.setState({ selectedDevice: device });
    }

    onAcceptClickHandler(device){
        this.setState({
            configuringDevice:device,
            modalPage: 1,
            selectedDevice: device
        })
        this.openModal();
    }

    onRejctedClickHandler(device){
        this.props.rejectDevice(device);
    }

    openModal(){
        this.setState({
            showModal: true,
            modalPage: 1
        })
    }

    closeModal = () =>{
        this.setState({
            showModal: false,
            modalPage: null
        })
    }


    renderHotels = () => {
        if (!this.props.hotels) return null;
        return this.props.hotels.map(hotel => {
            return <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
        });
    };

    handleHotelChange = (event) =>{
        this.setState({
            selectedHotel: event.target.value
        })
    }

    onNextClick = () =>{
        this.setState({
            modalPage: 2
        })
    }

    onPreviousPage = () =>{
        this.setState({
            modalPage: 1
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onAddConfiguration = () => {
        const obj = {
            body: {
                modbusHost: this.state.modbusHost,
                serverHost: this.state.serverHost,
                modbusPort: this.state.modbusPort,
                roomIdRegister: this.state.roomIdRegister,
                floorIdRegister: this.state.floorIdRegister,
                cardTypeRegister: this.state.cardTypeRegister,
                accessLevelRegisterLo: this.state.accessLevelRegisterLo,
                accessLevelRegisterHi: this.state.accessLevelRegisterHi,
                writeCommandRegister: this.state.writeCommandRegister,
                dispenserStatusRegister: this.state.dispenserStatusRegister,
                yearRegister: this.state.yearRegister,
                monthRegister: this.state.monthRegister,
                dayRegister: this.state.dayRegister,
                dispenserStatusErrorRegister: this.state.dispenserStatusErrorRegister,

            },
            accomodationId: this.state.selectedHotel,
            deviceId: this.state.selectedDevice.id
        }
        this.props.acceptDevice(obj);
        this.setState({
            selectedDevice: null
        })
        this.closeModal();
    }

    render() {
        return (
            <Fragment>
                { this._isMounted === true ? 
                <SockJsClient 
                    url={`${BASE_URL}/ws?token=${localStorage.getItem('authToken')}`} 
                    topics={['/topic/devices', '/topic/devices-heartbeat'] }
                    onMessage={(msg,topic) => {
                        if(topic === "/topic/devices-heartbeat"){
                            this.props.deviceHeartbeat(msg);
                        }
                        if(topic === "/topic/devices"){
                            this.props.newPendingDevicesRegistered(msg);
                        }
                     }}
                    ref={(client) => { this.clientRef = client }} 
                />
                : null
                }
                <Modal
                    isOpen={this.state.showModal}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => this.closeModal()}
                    style={ modalStyle }
                >
                    <div className="config-modal">
                        <div className="config-modal__header">
                            <h3>Configure device</h3>
                            <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={() => this.closeModal()} />
                        </div>
                    {
                        this.state.modalPage === null || this.state.modalPage === 1
                        ?

                        <div>
                        <div className="config-modal__content">
                            
                                <div className="modal__content__grid__input half-width" style={{marginBottom: '2rem'}}>
                                    <p>Select hotel</p>
                                    <select name="accessLevel" onChange={this.handleHotelChange} value={this.state.selectedHotel}>
                                        {this.renderHotels()}
                                    </select>
                                </div>

                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Server host: </p>
                                    </div>
                                    <input type="text" name="serverHost" onChange={this.handleChange} value={this.state.serverHost} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Modbus host: </p>
                                    </div>
                                    <input type="text" name="modbusHost" onChange={this.handleChange} value={this.state.modbusHost} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Modbus port: </p>
                                    </div>
                                    <input type="number" name="modbusPort" onChange={this.handleChange} value={this.state.modbusPort} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Room id register: </p>
                                    </div>
                                    <input type="number" name="roomIdRegister" onChange={this.handleChange} value={this.state.roomIdRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Floor id register: </p>
                                    </div>
                                    <input type="number" name="floorIdRegister" onChange={this.handleChange} value={this.state.floorIdRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Card type register: </p>
                                    </div>
                                    <input type="number" name="cardTypeRegister" onChange={this.handleChange} value={this.state.cardTypeRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Access level LO: </p>
                                    </div>
                                    <input type="number" name="accessLevelRegisterLo" onChange={this.handleChange} value={this.state.accessLevelRegisterLo} autoComplete="false" />
                                </div>
                                
                            </div>
                            <div className="config-modal__footer" style={{paddingTop: 0}}>
                                <button className="config-modal__footer-btn" onClick={this.onNextClick}>
                                    <div>
                                        <p>Next</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="config-modal__content">
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Access level HI: </p>
                                    </div>
                                    <input type="number" name="accessLevelRegisterHi" onChange={this.handleChange} value={this.state.accessLevelRegisterHi} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Write command register: </p>
                                    </div>
                                    <input type="number" name="writeCommandRegister" onChange={this.handleChange} value={this.state.writeCommandRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Dispenser status register: </p>
                                    </div>
                                    <input type="number" name="dispenserStatusRegister" onChange={this.handleChange} value={this.state.dispenserStatusRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Year register: </p>
                                    </div>
                                    <input type="number" name="yearRegister" onChange={this.handleChange} value={this.state.yearRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Month register: </p>
                                    </div>
                                    <input type="number" name="monthRegister" onChange={this.handleChange} value={this.state.monthRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Day register: </p>
                                    </div>
                                    <input type="number" name="dayRegister" onChange={this.handleChange} value={this.state.dayRegister} autoComplete="false" />
                                </div>
                                <div className="config-modal__content__input">
                                    <div className="config-modal__content__input-title">
                                        <p>Dispenser error register: </p>
                                    </div>
                                    <input type="number" name="dispenserStatusErrorRegister" onChange={this.handleChange} value={this.state.dispenserStatusErrorRegister} autoComplete="false" />
                                </div>
                                
                            </div>
                            <div className="config-modal__footer">
                                <button className="modal__footer-btn-reset" onClick={this.onPreviousPage}>
                                        Previous
                                </button>
                                <button className="modal__footer-btn" onClick={this.onAddConfiguration}>
                                    <div>
                                        <p>Add</p>
                                        
                                    </div>
                                </button>
                            </div>
                        </div>
                        }
                    </div>
                </Modal>
                <div className="devices__header">
                    <div className="devices__header-item" onClick={() => this.onLinkClicked('accepted')}>
                        <a href="#accepted" className={ this.state.active === 'accepted' ? 'devices__header-active' : '' }>Accepted</a>
                    </div>
                    <div className="devices__header-item" onClick={() => this.onLinkClicked('pending')}>
                        <a href="#pending" className={ this.state.active === 'pending' ? 'devices__header-active' : '' }>Pending</a>
                    </div>
                    <div className="devices__header-item" onClick={() => this.onLinkClicked('rejected')}>
                        <a href="#rejected" className={ this.state.active === 'rejected' ? 'devices__header-active' : '' }>Rejected</a>
                    </div>
                </div>
                <div className="devices">
                    {this.renderDevicesList()}
                </div>
            </Fragment>
        )
    }

    renderAcceptedDevices = devices => {
        return devices.map(device => {
            let date = new Date(device.lastTimeOnline);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let month = date.getMonth() + 1;
            let d = date.getDate() + "-" + month + "-" + date.getFullYear();
            let formattedTime = d + " "+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            let timestamp = Math.round((new Date()).getTime());
            let lastTimeOnline = Math.round((timestamp - device.lastTimeOnline)/1000);
            let minutesOfline = 0;
            let hoursOfline = 0;
            let secondsLeft = 0;
            let formatedInactiveTime;
            let online = lastTimeOnline > 60 ? false : true;

            if (lastTimeOnline > 3600) {
                hoursOfline = Math.floor(lastTimeOnline / 3600);
                let secondsLeft1 = lastTimeOnline - (hoursOfline * 3600);
                if (secondsLeft1 > 59 ) {
                    minutesOfline = Math.floor(secondsLeft1/60);
                }
                secondsLeft = secondsLeft1 - (minutesOfline*60);
                formatedInactiveTime = hoursOfline +"h "+minutesOfline+"m "+secondsLeft+"s ";
            } else {
                if (lastTimeOnline > 60){
                    minutesOfline = Math.floor(lastTimeOnline/60);
                    secondsLeft = lastTimeOnline - (minutesOfline * 60);
                    formatedInactiveTime = minutesOfline+"m "+secondsLeft+"s ";
                } else {
                    formatedInactiveTime = lastTimeOnline +"s "; 
                }
            }

            let config = null;
            let activeConfig = null;

            if (this.state.selectedDevice) {
                config = this.state.selectedDevice.configurations.filter(conf => conf.active === true)
                activeConfig = config[0];
            }

            const selected = this.state.selectedDevice && this.state.selectedDevice.id === device.id ? true : false;
            return (
                <Fragment key={device.id}>
                    <tr onClick={() => this.onSelectDevice(device)}>
                        <td>{device.macAddress}</td>
                        <td>{formattedTime}</td>
                        <td>{formatedInactiveTime}</td>
                        <td>{ device.deviceAuthToken}</td>
                    </tr>
                    {
                        selected ?
                            <DeviceDetails device={this.state.selectedDevice} activeConfig={activeConfig} formattedTime={formattedTime} onNewConfiguration={this.onNewConfiguration} online={online}/>
                        : null
                    }
                </Fragment>
            );
        })
    }

    renderPendingDevies = devices => {
        return devices.map(device => {
            let date = new Date(device.lastTimeOnline);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let month = date.getMonth() + 1;
            let d = date.getDate() + "-" + month + "-" + date.getFullYear();
            let formattedTime = d + " "+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

            return (
                <tr key={device.id}>
                    <td>{device.macAddress}</td>
                    <td>{formattedTime}</td>
                    <td>Pending</td>
                    <td>
                        <div style={{display: 'flex'}}>
                            <button className="btn-accept" style={{marginRight: '1rem'}} onClick={() => this.onAcceptClickHandler(device)}>accept</button>
                            <button className="btn-reject" onClick={() => this.onRejctedClickHandler(device)}>reject</button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    renderRejectedDevies(devices){
        return devices.map(device => {
            let date = new Date(device.lastTimeOnline);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let month = date.getMonth + 1;
            let d = date.getDate() + "-" + month + "-" + date.getFullYear();
            let formattedTime = d + " "+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

            return (
                <tr key={device.id}>
                    <td>{device.macAddress}</td>
                    <td>{formattedTime}</td>
                    <td>Rejected</td>
                </tr>
            );
        });
    }

    renderDevicesList = () => {
        switch(this.state.active) {
            case 'accepted':
                return (
                    <table className="devices__table" style={{minWidth: '104rem'}}>
                        <thead>
                            <tr>
                                <th>Mac address</th>
                                <th>Last check-in</th>
                                <th>Inactive for</th>
                                <th>Auth token</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderAcceptedDevices(this.props.acceptedDevices)}
                        </tbody>
                    </table>
                )
            case 'pending':
                return (
                    <table className="devices__table">
                        <thead>
                            <tr>
                                <th>Mac address</th>
                                <th>Time of request</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderPendingDevies(this.props.pendingDevices)}
                        </tbody>
                    </table>
                )
            case 'rejected':
                return (
                    <table className="devices__table">
                        <thead>
                            <tr>
                                <th>Mac address</th>
                                <th>Time of request</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRejectedDevies(this.props.rejectedDevices)}
                        </tbody>
                    </table>
                )
            default:
                return null;
        }
    }
}

const mapStateToProps = state => ({
    acceptedDevices: state.devices.acceptedDevices,
    pendingDevices: state.devices.pendingDevices,
    rejectedDevices: state.devices.rejectedDevices,
    hotels: state.hotel.hotels,
    deviceConfigUpdated: state.devices.deviceConfigUpdated,
    updatedDevice: state.devices.updatedDevice
});

const mapDispatchToProps = dispatch => ({
    fetchAcceptedDevices: () => dispatch(fetchAcceptedDevices()),
    fetchPendingDevices: () => dispatch(fetchPendingDevices()),
    fetchRejectedDevices: () => dispatch(fetchRejectedDevices()),
    newPendingDevicesRegistered: (device) => dispatch(newPendingDevicesRegistered(device)),
    deviceHeartbeat: (device) => dispatch(deviceHeartbeat(device)),
    rejectDevice: (device) => dispatch(rejectDevice(device)),
    acceptDevice: (deviceObj) => dispatch(acceptDevice(deviceObj)),
    resetUpdatedDevice: () => dispatch(resetUpdatedDevice())
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);