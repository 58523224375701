import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { getHotelsByRole, getHotelsByRoleWithLogo } from '../../store/actions/hotel';
import { feedbackResetStatus, feedbackIsFetching  } from '../../store/actions/feedback';
import NewAccommodationDialog from '../Accommodation/NewAccommodationDialog/NewAccommodationDialog';
import EmailIcon from '../../assets/svg/email.svg';
import PhoneIcon from '../../assets/svg/phone.svg';
import WorldIcon from '../../assets/svg/world.svg';
import HotelLogo from '../../assets/svg/hotel.svg';
import LocationIcon from '../../assets/svg/location.svg';
import ContentLoader from "react-content-loader"

const INIT_STATE = {
    showNewAccommodationDialog: false
}

class DashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    componentDidMount() {
        this.props.getHotels();
        this.props.feedbackIsFetching();
        this.props.getHotelsByRoleWithLogo();
    }

    hotelRedirection = id => {
        history.push(`/accommodation/overview/${id}`);
    }

    onShowNewAccommodationDialog = () => {
        this.setState({
            showNewAccommodationDialog: true
        });
    }

    onCloseNewAccommodationDialog = () => {
        this.setState({
            showNewAccommodationDialog: false
        });
    }

    render() {
        return (
            <Fragment>
                <NewAccommodationDialog
                    show={this.state.showNewAccommodationDialog} 
                    onCloseDialog={this.onCloseNewAccommodationDialog}
                />
                <div className="dashboard__header">
                    <h2>Dashboard</h2>
                </div>
                <div className="dashboard">
                    <div className="dashboard__hotels">
                        <div className="dashboard__hotels__header">
                            <h3 className="dashboard__hotels__header-title">Hotels</h3>
                            {
                                (this.props.user && this.props.user.authority !== 'STAFF') ?
                                    <button className="dashboard__hotels__header-button" onClick={() => this.onShowNewAccommodationDialog()}>
                                        Add new hotel
                                    </button>
                                : null
                            }
                        </div>
                        <div className="dashboard__hotels-list">
                            {this.renderHotels()}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    renderHotelLogo = (hotel)=>{
        if(hotel.logo === null && this.props.feedbackInfo.isFetching){
            return(
                <div className="dashboard__hotels__item-img-load">
                    <ContentLoader 
                    speed={2}
                    width={400}
                    height={160}
                    viewBox="0 0 400 160"
                    backgroundColor="#f4f6fa"
                    foregroundColor="#dee0e3"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="235" height="300" /> 
                </ContentLoader>
                </div>
            )
        }
        if(hotel.logo === null && this.props.feedbackInfo.isFetching === false){
            return (
                <img className="dashboard__hotels__item-img" src={HotelLogo} alt="hotel" />
            )
        }
        else {
            return(
                <img className="dashboard__hotels__item-img" src={`data:image/jpeg;base64,${hotel.logo}`} alt="hotel" />
            )
        }
    }

    renderHotels = () => {
        if (!this.props.hotels) return null;
        const hotelCompoenents = this.props.hotels.map(hotel => {
            return (
                <div className="dashboard__hotels__item" key={hotel.id} onClick={() => this.hotelRedirection(hotel.id)}>
                    <div>
                        {this.renderHotelLogo(hotel)}
                        <div className="dashboard__hotels__item__content">
                            <h3 className="dashboard__hotels__item__content-title">{hotel.name}</h3>
                            <div className="dashboard__hotels__item__content__description">
                                <img src={LocationIcon} alt="location"/>
                                <p>{hotel.address + ', ' + hotel.city}</p>
                            </div>
                            <div className="dashboard__hotels__item__content__description">
                                <img src={WorldIcon} alt="world" />
                                <p>{hotel.city + ', ' + hotel.country}</p>
                            </div>
                            <div className="dashboard__hotels__item__content__description">
                                <img src={PhoneIcon} alt="phone"/>
                                <p>{hotel.phoneNumber}</p>
                            </div>
                            <div className="dashboard__hotels__item__content__description" style={{marginBottom: '1rem'}}>
                                <img src={EmailIcon} alt="mail" />
                                <p>{hotel.email}</p>
                            </div>
                        </div>
                    </div>
                    <button className="dashboard__hotels__item__content-btn">See details</button>
                </div>
            )
        });

        // Plus sign for adding new accommodation
        // if (this.props.user && this.props.user.authority !== 'STAFF') {
        //     hotelCompoenents.push(
        //         <div className="dashboard__bonus" key={'bonus'}>
        //             <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="new-hotel" onClick={() => this.onShowNewAccommodationDialog()} />
        //         </div>
        //     );
        // }

        return hotelCompoenents;
    }
}

const mapStateToProps = state => ({
    hotels: state.hotel.hotels,
    user: state.auth.user,
    feedbackInfo: state.feedbackInfo
});

const mapDispatchToProps = dispatch => ({
    getHotels: () => dispatch(getHotelsByRole()),
    getHotelsByRoleWithLogo: () => dispatch(getHotelsByRoleWithLogo()),
    feedbackResetStatus: () => dispatch(feedbackResetStatus()),
    feedbackIsFetching: () => dispatch(feedbackIsFetching())

});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);