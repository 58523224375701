import { FEEDBACK_SUCCESSFUL, FEEDBACK_FAILED, FEEDBACK_IS_FETCHING, FEEDBACK_RESET_STATUS } from '../constants';

const INIT_STATE = {
    isFetching: false,
    message: '',
    isSuccessful: null
}

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case FEEDBACK_IS_FETCHING:
            return { isFetching: true, message: '', isSuccessful: null };
        case FEEDBACK_SUCCESSFUL:
            return { isFetching: false, message: action.payload, isSuccessful: true };
        case FEEDBACK_FAILED:
            return { isFetching: false, message: action.payload, isSuccessful: false };
        case FEEDBACK_RESET_STATUS:
            return { isFetching: false, message: '', isSuccessful: null };
        default:
            return state;
    }
}