import apiBase from '../../apis/api';
import { ADD_NEW_ROOM, REMOVE_ROOM, GET_ROOM, SET_ROOM_TO_NULL, EDIT_ROOM, EDIT_CONTROLLER_NUMBER } from '../constants';
import { makeHeader } from './base';
import { feedbackSuccessful, feedbackFailed, feedbackIsFetching } from './feedback';

export const addNewRoom = newRoom => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.post(`/room`, newRoom, headers);
            if (response.status !== 200 && response.status !== 201) throw new Error();
            dispatch(feedbackSuccessful('New room added successfully!'));
            response.data.floorId = newRoom.floorId;
            const payload = {
                floorId: newRoom.floorId,
                data: response.data
            }
            dispatch({ type: ADD_NEW_ROOM, payload });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new room!'));
        }
    }
}

export const removeRoom = (floorId, roomId) => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.delete(`/room/${roomId}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Room removed successfully!'));
            dispatch({ type: REMOVE_ROOM, payload: { floorId, roomId }});
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message));
        }
    }
}

export const editRoom = room => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.put(`/room`, room, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Room edited successfully!'));
            dispatch({ type: EDIT_ROOM, payload: response.data});
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message));
        }
    }
}

export const editRoomControllerNumber = room => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.put(`/room/controller-number`, room, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Controller number edited successfully!'));
            dispatch({ type: EDIT_CONTROLLER_NUMBER, payload: response.data});
        } catch (e) {
            console.log(e);
            dispatch(feedbackFailed(e.response.data.message));
        }
    }
}

export const getRoomById = id => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get(`/room/${id}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: GET_ROOM, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const setRoomToNull = () => {
    return { type: SET_ROOM_TO_NULL };
}