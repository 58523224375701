import React, { Component } from 'react';
import Modal from 'react-modal';
import Spinner from '../Spinner/Spinner';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/svg/close.svg';

Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

const INIT_STATE = {
    givenId: '',
    level: ''
}

class UpdateFloorDialog extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = event => {
        event.preventDefault();
        const {givenId, level} = this.state;
        this.setState(INIT_STATE, () => {
            this.props.onUpdate({
                givenId,
                level
            });
        });
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.props.onClose}
                style={modalStyle}
            >
                <div className="update-floor__modal">
                    <div className="update-floor__modal__header">
                        <h3>{this.props.title}</h3>
                        <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={this.props.onClose} />
                    </div>
                    <div className="update-floor__modal__content">
                        <div className="update-floor__modal__content__input">
                            <div className="update-floor__modal__content__input-title">
                                <p>Station:</p>
                                <p className="update-floor__modal__content__input-title-light">{`(current: ${this.props.selectedItem && this.props.selectedItem.level})`}</p>
                            </div>
                            <input type="number" name="level" onChange={this.handleChange} value={this.state.level} autoComplete="false" />
                        </div>
                        <div className="update-floor__modal__content__input">
                            <div className="update-floor__modal__content__input-title">
                                <p>id:</p>
                                <p className="update-floor__modal__content__input-title-light">{`(current: ${this.props.selectedItem && this.props.selectedItem.idGiven})`}</p>
                            </div>
                            <input type="number" name="givenId" onChange={this.handleChange} value={this.state.givenId} autoComplete="false" />
                        </div>
                    </div>
                    <div className="update-floor__modal__footer">
                        <button className="update-floor__modal__footer-btn" onClick={this.onSubmit}>
                            <div>
                                <p>Edit</p>
                                {
                                    this.props.feedbackInfo.isFetching ?
                                    <Spinner />
                                    : null
                                }
                            </div>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo
});

export default connect(mapStateToProps, null)(UpdateFloorDialog);