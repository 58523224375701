import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { logout } from '../../store/actions/auth';
import ArrowDownIcon from '../../assets/svg/arrow-down.svg';
import Avatar from 'react-avatar';

class Header extends Component {

    redirectToOverview = () => {
        if (this.props.selectedHotel) history.push(`/accommodation/overview/${this.props.selectedHotel.id}`);
    }

    redirectToConfiguration = () => {
        if (this.props.selectedHotel) history.push(`/accommodation/configuration/${this.props.selectedHotel.id}`);
    }

    redirectToDevices = () => {
        if (this.props.selectedHotel) history.push('/devices#accepted');
    }

    render() {
        if (!this.props.user) return null;
        return (
            <div className="header">
                {this.props.isLoggedIn ? 
                <Fragment>
                    <div className="header__right-side">
                        <div className="header__dropdown">
                            <div className="header__btn">
                                <Avatar size="40" className="users__user-content-avatar" 
                                    color={Avatar.getRandomColor('sitebase', ['#d14d4d', '#d14d4d', '#d14d4d'])} 
                                    name={this.props.user.username} round={true} />

                                <div className="header__avatar__name">
                                    <p className="header__avatar__name-fullname">{this.props.user.username}</p>
                                    <p className="header__avatar__name-role">{this.props.user.authority}</p>
                                </div>
                                <img src={ArrowDownIcon} alt="arrow-down" className="header__btn__arrow-down"/>
                            </div>
                            <div className="header__dropdown-content">
                                <button onClick={() => history.push('/dashboard')}>Dashboard</button>
                                <button onClick={() => this.redirectToOverview()}>Overview</button>
                                <button onClick={() => this.redirectToConfiguration()}>Configuration</button>
                                {this.props.user.authority === 'ADMIN' ? <button onClick={() => this.redirectToDevices()}>Devices</button> : null}
                                <hr />
                                <button onClick={() => {history.push('/account')}}>Account</button>
                                {this.props.user.authority === 'ADMIN' ? <button onClick={() => {history.push('/users')}}>Users</button> : null}
                                <hr />
                                <button onClick={() => this.props.logout()}>Logout</button>
                            </div>
                        </div>
                    </div>
                </Fragment>
                : null
            }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    selectedHotel: state.hotel.selectedHotel
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);