import {
  HOTELS,
  GET_HOTEL,
  GET_ROOM,
  SET_ROOM_TO_NULL,
  POST_RESERVATION,
  UPDATE_RESERVATION,
  DECLINE_RESERVATION,
  ADD_NEW_FLOOR,
  EDIT_FLOOR,
  REMOVE_FLOOR,
  ADD_NEW_ACCESS_LEVEL,
  EDIT_ACCESS_LEVEL,
  REMOVE_ACCESS_LEVEL,
  ADD_NEW_CARD_TYPE,
  EDIT_CARD_TYPE,
  REMOVE_CARD_TYPE,
  ADD_NEW_ROOM,
  REMOVE_ROOM,
  ADD_NEW_HOTEL,
  EDIT_ROOM,
  STAFF_ADDED,
  STAFF_REMOVED,
  EDIT_CONTROLLER_NUMBER,
  LOGO_FETCHED,
  LOGO_UPLOADED,
  HOTEL_UPDATED,
} from "../constants";

const INITIAL_STATE = {
  hotels: null,
  selectedHotel: null,
  selectedRoom: null,
};

export default (state = INITIAL_STATE, action) => {
  let floor = null;
  let room = null;
  switch (action.type) {
    case HOTELS:
      const defaultHotel = action.payload.length > 0 ? action.payload[0] : null;
      return {
        ...state,
        hotels: action.payload,
        selectedHotel:
          state.selectedHotel === null ? defaultHotel : state.selectedHotel,
      };
    case GET_HOTEL:
      return { ...state, selectedHotel: action.payload };
    case ADD_NEW_HOTEL:
      return { ...state, hotels: [...state.hotels, action.payload] };
    case GET_ROOM:
      return { ...state, selectedRoom: action.payload };
    case SET_ROOM_TO_NULL:
      return { ...state, selectedRoom: null };
    case POST_RESERVATION:
      room = {
        ...state.selectedRoom,
        reservations: [...state.selectedRoom.reservations, action.payload],
      };
      return { ...state, selectedRoom: room };
    case UPDATE_RESERVATION:
        console.log('updated', action.payload);
      room = {
        ...state.selectedRoom,
        reservations: state.selectedRoom.reservations.map((res) =>
          res.id === action.payload.id
            ? {
                ...action.payload,
              }
            : res
        ),
      };
      return { ...state, selectedRoom: room };

    case DECLINE_RESERVATION:
      return {
        ...state,
        selectedRoom: {
          ...state.selectedRoom,
          reservations: state.selectedRoom.reservations.filter(
            (reservation) => reservation.id + "" !== action.payload
          ),
        },
      };
    case ADD_NEW_FLOOR:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          floors: [...state.selectedHotel.floors, action.payload],
        },
      };
    case EDIT_FLOOR:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          floors: state.selectedHotel.floors.map((floor) =>
            floor.id === action.payload.id ? action.payload : floor
          ),
        },
      };
    case REMOVE_FLOOR:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          floors: state.selectedHotel.floors.filter(
            (floor) => floor.id !== action.payload
          ),
        },
      };
    case ADD_NEW_ACCESS_LEVEL:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          accessLevels: [...state.selectedHotel.accessLevels, action.payload],
        },
      };
    case EDIT_ACCESS_LEVEL:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          accessLevels: state.selectedHotel.accessLevels.map((accessLevel) =>
            accessLevel.id === action.payload.id ? action.payload : accessLevel
          ),
        },
      };
    case REMOVE_ACCESS_LEVEL:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          accessLevels: state.selectedHotel.accessLevels.filter(
            (accessLevel) => accessLevel.id !== action.payload
          ),
        },
      };
    case ADD_NEW_CARD_TYPE:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          cardTypes: [...state.selectedHotel.cardTypes, action.payload],
        },
      };
    case EDIT_CARD_TYPE:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          cardTypes: state.selectedHotel.cardTypes.map((cardType) =>
            cardType.id === action.payload.id ? action.payload : cardType
          ),
        },
      };
    case REMOVE_CARD_TYPE:
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          cardTypes: state.selectedHotel.cardTypes.filter(
            (cardType) => cardType.id !== action.payload
          ),
        },
      };
    case ADD_NEW_ROOM:
      floor = {
        ...state.selectedHotel.floors.filter(
          (floor) => floor.id === action.payload.floorId
        )[0],
      };
      floor.rooms = [...floor.rooms, action.payload.data];
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          floors: [
            ...state.selectedHotel.floors.filter(
              (floor) => floor.id !== action.payload.floorId
            ),
            floor,
          ],
        },
      };
    case REMOVE_ROOM:
      floor = {
        ...state.selectedHotel.floors.filter(
          (floor) => floor.id === action.payload.floorId
        )[0],
      };
      floor.rooms = floor.rooms.filter(
        (room) => room.id !== action.payload.roomId
      );
      return {
        ...state,
        selectedHotel: {
          ...state.selectedHotel,
          floors: [
            ...state.selectedHotel.floors.filter(
              (floor) => floor.id !== action.payload.floorId
            ),
            floor,
          ],
        },
      };
    case LOGO_FETCHED:
      return {
        ...state,
        selectedHotel: { ...state.selectedHotel, logo: action.payload },
      };
    case LOGO_UPLOADED:
      return { ...state, selectedHotel: action.payload };
    case EDIT_ROOM:
      let floorSearch = null;
      state.selectedHotel.floors.forEach((floor) => {
        floor.rooms.forEach((room) => {
          if (room.id === action.payload.id) {
            floorSearch = floor;
          }
        });
      });

      let updateRoom = null;
      floorSearch.rooms.forEach((room) => {
        if (room.id === action.payload.id) {
          updateRoom = room;
        }
      });
      updateRoom.idGiven = action.payload.idGiven;

      let editedFloorRooms = floorSearch.rooms.filter((room) =>
        room.id === action.payload.id
          ? {
              ...room,
            }
          : room
      );
      let editedFloors = state.selectedHotel.floors.filter((floor) =>
        floor.id === floorSearch.id
          ? {
              ...floor,
              rooms: editedFloorRooms,
            }
          : floor
      );

      let editedHotel = {
        ...state.selectedHotel,
        floors: editedFloors,
      };

      return {
        ...state,
        selectedHotel: editedHotel,
      };
    case EDIT_CONTROLLER_NUMBER:
      let floorSearch2 = null;
      state.selectedHotel.floors.forEach((floor) => {
        floor.rooms.forEach((room) => {
          if (room.id === action.payload.id) {
            floorSearch2 = floor;
          }
        });
      });

      let updateRoom2 = null;
      floorSearch2.rooms.forEach((room) => {
        if (room.id === action.payload.id) {
          updateRoom2 = room;
        }
      });
      updateRoom2.controllerNumber = action.payload.controllerNumber;

      let editedFloorRooms2 = floorSearch2.rooms.filter((room) =>
        room.id === action.payload.id
          ? {
              ...room,
            }
          : room
      );
      let editedFloors2 = state.selectedHotel.floors.filter((floor) =>
        floor.id === floorSearch2.id
          ? {
              ...floor,
              rooms: editedFloorRooms2,
            }
          : floor
      );

      let editedHotel2 = {
        ...state.selectedHotel,
        floors: editedFloors2,
      };

      return {
        ...state,
        selectedHotel: editedHotel2,
      };

    case STAFF_ADDED:
      if (action.payload.type === "OWNER") {
        return {
          ...state,
          selectedHotel: {
            ...state.selectedHotel,
            owners: [...state.selectedHotel.owners, action.payload],
          },
        };
      } else if (action.payload.type === "STAFF") {
        return {
          ...state,
          selectedHotel: {
            ...state.selectedHotel,
            staff: [...state.selectedHotel.staff, action.payload],
          },
        };
      }
      return state;
    case STAFF_REMOVED:
      if (action.payload.type === "OWNER") {
        return {
          ...state,
          selectedHotel: {
            ...state.selectedHotel,
            owners: state.selectedHotel.owners.filter(
              (owner) => owner.id !== action.payload.id
            ),
          },
        };
      } else if (action.payload.type === "STAFF") {
        return {
          ...state,
          selectedHotel: {
            ...state.selectedHotel,
            staff: state.selectedHotel.staff.filter(
              (staff) => staff.id !== action.payload.id
            ),
          },
        };
      }
      return state;
    case HOTEL_UPDATED:
      if (state.selectedHotel.id === action.payload.id) {
        return {
          ...state,
          selectedHotel: {
            ...state.selectedHotel,
            phoneNumber: action.payload.phoneNumber,
            city: action.payload.city,
            address: action.payload.address,
            country: action.payload.country,
          },
          hotels: state.hotels.map((hotel) =>
            hotel.id === action.payload.id
              ? {
                  ...hotel,
                  phoneNumber: action.payload.phoneNumber,
                  city: action.payload.city,
                  address: action.payload.address,
                  country: action.payload.country,
                }
              : hotel
          ),
        };
      } else {
        return {
          ...state,
          hotels: state.hotels.map((hotel) =>
            hotel.id === action.payload.id
              ? {
                  ...hotel,
                  phoneNumber: action.payload.phoneNumber,
                  city: action.payload.city,
                  address: action.payload.address,
                  country: action.payload.country,
                }
              : hotel
          ),
        };
      }
    default:
      return state;
  }
};
