import { combineReducers } from 'redux';

import authReducer from './auth';
import hotelReducer from './hotel'
import deviceReducer from '../reducers/devices';
import feedbackReducer from './feedback';

export default combineReducers({
    auth: authReducer,
    hotel: hotelReducer,
    feedbackInfo: feedbackReducer,
    devices: deviceReducer
});