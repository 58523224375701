import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import HotelSelector from '../../Header/HotelSelector/HotelSelector';
import { addNewFloor, addAccessLevel, addCardType, editFloor, editAccessLevel, editCardType,
removeFloor, removeAccessLevel, removeCardType } from '../../../store/actions/config';
import { removeAccomodationStaff, getHotelLogo, uploadLogo } from '../../../store/actions/hotel';
import { feedbackResetStatus, feedbackIsFetching } from '../../../store/actions/feedback';
import { getHotelById, updateAccommodation } from '../../../store/actions/hotel';
import { toast } from 'react-toastify';
import UpdateFloorDialog from '../../Utils/UpdateFloorDialog/UpdateFloorDialog';
import ImageCrop from '../../Utils/ImageCrop';
import RemoveDialog from '../../Utils/RemoveDialog/RemoveDialog';
import AddConfigDialog from './AddConfigDialog/AddConfigDialog';
import UpdateConfigGivenIdDialog from './UpdateConfigGivenIdDialog/UpdateConfigGivenIdDialog';
import AddStaffDialog from './AddStaffDialog/AddStaffDialog';
import Avatar from 'react-avatar';
import Delete from '../../../assets/svg/delete.svg';
import Edit from '../../../assets/svg/edit.svg';
import HotelLogo from '../../../assets/svg/hotel.svg';
import ImageUploader from 'react-images-upload';
import AccessLevelLogo from '../../../assets/svg/access_levels.svg';
import CardLogo from '../../../assets/svg/card_type.svg';
import Floor from '../../../assets/svg/floor.svg';
import Staff from '../../../assets/svg/staff.svg';
import Spinner from '../../Utils/Spinner/Spinner';

const INIT_STATE = {
    showAddDialog: false,
    showEditAccessLevelDialog: false,
    showEditCardTypeDialog: false,
    showEditFloorDialog: false,
    showRemoveDialog: false,
    showAddStaffDialog: false,
    title: '',
    selectedItem: null,
    pictures: [],
    showUploadImage: false,
    image: null,
    showUpdateInfo: false,
    city: "",
    address: "",
    country: "",
    phoneNumber: ""

}

class AccommodationConfiguration extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    updateHotelInfo = hotel => {
        const {address, city, country, phoneNumber} = hotel;
        this.setState({
            address,
            city,
            country,
            phoneNumber
        })
    }

    onDrop = async (picture) =>{
        if(picture[0].name.split('.')[1] === 'svg'){
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ 
                image: reader.result,
                imageUri: picture[0]
            })

        }

        if (picture[0]) {
            reader.readAsDataURL(picture[0]);
        }

    }

    componentDidMount() {
        this.props.getHotelById(this.props.match.params.id);
        if (this.props.hotel) {
            this.updateHotelInfo(this.props.hotel);
        }
    }

    toggleShowUploadImage = () => {
        this.setState({
            showUploadImage: !this.state.showUploadImage
        })
    }

    openAddDialog = title => {
        this.setState({
            showAddDialog: true,
            title
        });
    }

    closeAddDialog = () => {
        this.setState({
            showAddDialog: false,
            title: ''
        })
    }

    openRemoveDialog = (type, object) => {
        if (!this.props.hotel) return;
        let selectedItem = null;
        let title = '';

        if (type === 'ACCESS_LEVEL') {
            selectedItem = this.props.hotel.accessLevels.filter(accessLevel => accessLevel.id === object.id)[0];
            title = 'Remove access level ' + object.name;
        } else if (type === 'CARD_TYPE') {
            selectedItem = this.props.hotel.cardTypes.filter(cardType => cardType.id === object.id)[0];
            title = 'Remove card type ' + object.name;
        } else if (type === 'FLOOR') {
            selectedItem = this.props.hotel.floors.filter(floor => floor.id === object.id)[0];
            title = 'Remove floor ' + object.name;
        } else if (type === 'HOTEL_STAFF') {
            selectedItem = object;
            title = "Remove hotel staff";
        }
        
        this.setState({
            title,
            selectedItem,
            showRemoveDialog: true
        });
    }

    closeRemoveDialog = () => {
        this.setState({
            title: '',
            selectedItem: null,
            showRemoveDialog: false
        });
    }

    onShowAddStaffDialog = () => {
        this.setState({
            showAddStaffDialog: true
        })
    }

    onCloseAddStaffDialog = () => {
        this.setState({
            showAddStaffDialog: false
        })
    }

    openEditDialog = (type, object) => {
        if (!this.props.hotel) return;
        let selectedItem = null;
        let title = '';
        let showEditAccessLevelDialog = false;
        let showEditCardTypeDialog = false;
        let showEditFloorDialog = false;

        if (type === 'ACCESS_LEVEL') {
            selectedItem = this.props.hotel.accessLevels.filter(accessLevel => accessLevel.id === object.id)[0];
            title = 'Edit access level ' + object.name;
            showEditAccessLevelDialog = true;
        } else if (type === 'CARD_TYPE') {
            selectedItem = this.props.hotel.cardTypes.filter(cardType => cardType.id === object.id)[0];
            title = 'Edit card type ' + object.name;
            showEditCardTypeDialog = true;
        } else if (type === 'FLOOR') {
            selectedItem = this.props.hotel.floors.filter(floor => floor.id === object.id)[0];
            title = 'Edit floor ' + object.name;
            showEditFloorDialog = true;
        }
        
        this.setState({
            title,
            selectedItem,
            showEditAccessLevelDialog,
            showEditCardTypeDialog,
            showEditFloorDialog
        });
    }

    closeEditDialog = () => {
        this.setState({
            title: '',
            selectedItem: null,
            showEditFloorDialog: false,
            showEditAccessLevelDialog: false,
            showEditCardTypeDialog: false
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onAddNew = name => {
        if (!this.props.hotel) return;
        const newTuple = {
            accommodationId: this.props.hotel.id,
            name
        };
        if (this.state.title.includes('Add new floor')) {
            this.props.addNewFloor(newTuple);
        } else if (this.state.title.includes('Add new access level')) {
            this.props.addAccessLevel(newTuple);
        } else if (this.state.title.includes('Add new card type')) {
            this.props.addCardType(newTuple);
        }
    }

    onUpdateFloor = response => {
        this.props.editFloor({
            id: this.state.selectedItem.id,
            idGiven: response.givenId,
            level: response.level
        });
    }

    onUpdateAccessLevel = updatedGivenId => {
        this.props.editAccessLevel({
            id: this.state.selectedItem.id,
            idGiven: updatedGivenId
        });
    }

    onUpdateCardType = updatedGivenId => {
        this.props.editCardType({
            id: this.state.selectedItem.id,
            idGiven: updatedGivenId
        });
    }

    onDelete = () => {
        const selected = this.state.selectedItem;
        if (selected) {
            if (this.state.title.includes('Remove floor')) {
                this.props.removeFloor(selected.id);
            } else if (this.state.title.includes('Remove access level')) {
                this.props.removeAccessLevel(selected.id);
            } else if (this.state.title.includes('Remove card type')) {
                this.props.removeCardType(selected.id);
            } else if (this.state.title.includes('Remove hotel staff')) {
                this.props.removeAccomodationStaff(selected);
            }
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidUpdate() {
        if (this.props.feedbackInfo.isSuccessful === true) {
            toast.success(this.props.feedbackInfo.message);
            this.setState(INIT_STATE);
            if(this.props.feedbackInfo.message === "Accommodation updated successfully!"){
                if (this.props.hotel) {
                    const {address, city, country, phoneNumber} = this.props.hotel;
                    this.setState({
                        address,
                        city,
                        country,
                        phoneNumber
                    })
                }
            }
            this.props.feedbackResetStatus();
        } else if (this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    renderLogoPart = () =>{
        if(this.props.isFetching === true){
            return(
                <div className="accommodation__config__hotel__item__content__spinner">
                    <Spinner/>
                </div>
            )
        }
        if(this.props.hotel.logo === null){
            return(
                <img className="accommodation__config__hotel__item-logo" src={HotelLogo} alt="hotel" />
            )
        }else{
            return(
                <img className="accommodation__config__hotel__item-logo" src={`data:image/jpeg;base64,${this.props.hotel.logo}`} alt="hotel" />
            )
        }
    }

    toggleShowUpdateDialog = () => {
        this.setState({
            showUpdateInfo: !this.state.showUpdateInfo
        })
    }

    onUpdateSubmit = () => {
        const updateObj = {
            address: this.state.address,
            city: this.state.city,
            phone: this.state.phoneNumber,
            country: this.state.country,
            id: this.props.hotel.id
        }
        this.props.updateAccommodation(updateObj);
    }

    renderHotelInfo = () => {
        if (!this.props.hotel) return null;
        return(
            <div className="accommodation__config__hotel__item__hotel-info">
            <div className="accommodation__config__hotel__item__hotel-info__content__informations">
                <h3 className="accommodation__config__hotel__item__hotel-info__content__informations-title">Hotel information</h3>     
                <img src={Edit} alt="correct"
                className="accommodation__config__hotel__item__hotel-info__content__informations-edit" 
                onClick={this.toggleShowUpdateDialog}
            />                       
            </div>
            <div className="accommodation__config__hotel__item__hotel-info__content">
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel name:</h3>
                    <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.name}</p>
                </div>
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel address:</h3>
                    {
                        this.state.showUpdateInfo ?
                        <input type="text" name="address" onChange={this.handleChange} value={this.state.address} autoComplete="false" />
                        :
                        <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.address}</p>
                    }
                </div>
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel city:</h3>
                    {
                        this.state.showUpdateInfo ?
                        <input type="text" name="city" onChange={this.handleChange} value={this.state.city} autoComplete="false" />
                        :
                        <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.city}</p>
                    }
                </div>
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel country:</h3>
                    {
                        this.state.showUpdateInfo ?
                        <input type="text" name="country" onChange={this.handleChange} value={this.state.country} autoComplete="false" />
                        :
                        <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.country}</p>                    
                    }
                </div>
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel phone:</h3>
                    {
                        this.state.showUpdateInfo ?
                        <input type="text" name="phoneNumber" onChange={this.handleChange} value={this.state.phoneNumber} autoComplete="false" />
                        :
                        <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.phoneNumber}</p>
                    }
                </div>
                <div className="accommodation__config__hotel__item__hotel-info__content__description">
                    <h3 className="accommodation__config__hotel__item__hotel-info__content__description-title">Hotel email:</h3>
                    <p className="accommodation__config__hotel__item__hotel-info__content__description-title">{this.props.hotel.email}</p>
                </div>
                {
                    this.state.showUpdateInfo === true ?
                    <div className="accommodation__config__hotel__item__hotel-info__content">
                        <button className="accommodation__config__hotel__item__hotel-info__content-button-submit" onClick={this.onUpdateSubmit}>
                            <p>Update info</p>
                        </button>
                        <button className="accommodation__config__hotel__item__hotel-info__content-button-cancel" onClick={this.toggleShowUpdateDialog}>
                            <p>Cancel</p>
                        </button>
                    </div>
                    :
                    null
                }

            </div>
        </div>
        )
    }

    renderHotelLogo = () => {
        if (!this.props.hotel) return null;
        return(
            <div className="accommodation__config__hotel__item__hotel-info">
                <div className="accommodation__config__hotel__item__content">
                    
                    <h3 className="accommodation__config__hotel__item-logo-text">Your hotel logo</h3>
                    <div className="accommodation__config__hotel__item__content__logo">
                        {
                            this.renderLogoPart()
                        }
                    </div>
                    <button className="accommodation__config__hotel__item-button" onClick={this.toggleShowUploadImage}>
                        <p>
                            {
                                this.state.showUploadImage ? 
                                "Cancel"
                                :
                                "Change your logo"
                            }
                        </p>
                    </button>
                    {
                        this.state.showUploadImage ?
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose image'
                            onChange={this.onDrop}
                            imgExtension={['.jpg', '.png', '.svg', 'jpeg']}
                            maxFileSize={15000000}
                            singleImage={true}
                            label="Max file size 15mb"
                        />
                        :
                        null    
                    }
                </div>
            </div>
        )
    }
    
    closeCropper = () => {
        this.setState({
            image: null
        })
    }

    render() {
        if(this.state.image !== null){
            return(
                <ImageCrop image={this.state.image} imageUri={this.state.imageUri} hotel={this.props.hotel.id} close={this.closeCropper}/>
            )
        }else
        return (
            <Fragment>
                {
                    this.state.image !== null ?
                    <ImageCrop image={this.state.image} imageUri={this.state.imageUri} hotel={this.props.hotel.id} close={this.closeCropper}/>
                    :
                    null
                }
                <AddStaffDialog
                    show={this.state.showAddStaffDialog}
                    title="Add new staff member"
                    onClose={this.onCloseAddStaffDialog}
                    hotelStaff={!this.props.hotel ? [] : this.props.hotel.owners.concat(this.props.hotel.staff) }
                    hotel={this.props.hotel}
                />

                <AddConfigDialog
                    show={this.state.showAddDialog}
                    title={this.state.title}
                    onClose={this.closeAddDialog}
                    onAdd={this.onAddNew}
                />

                <UpdateConfigGivenIdDialog
                    show={this.state.showEditAccessLevelDialog}
                    title={this.state.title}
                    selectedItem={this.state.selectedItem}
                    onClose={this.closeEditDialog}
                    onUpdate={this.onUpdateAccessLevel}
                />

                <UpdateFloorDialog
                    show={this.state.showEditFloorDialog}
                    title={this.state.title}
                    selectedItem={this.state.selectedItem}
                    onClose={this.closeEditDialog}
                    onUpdate={this.onUpdateFloor}
                />

                <UpdateConfigGivenIdDialog
                    show={this.state.showEditCardTypeDialog}
                    title={this.state.title}
                    selectedItem={this.state.selectedItem}
                    onClose={this.closeEditDialog}
                    onUpdate={this.onUpdateCardType}
                />

                <RemoveDialog
                    show={this.state.showRemoveDialog}
                    title={this.state.title} 
                    onClose={this.closeRemoveDialog}
                    onRemove={this.onDelete}
                />

                <HotelSelector />

                <div className="accommodation__config">

                    <div className="accommodation__config__hotel__item hotel__config">
                        {this.renderHotelLogo()}
                    </div>
                    <div className="accommodation__config__hotel__item hotel__config-info">
                        {this.renderHotelInfo()}
                    </div>

                    <div className="accommodation__config__item users__config">
                        <div className="accommodation__config__item__header">
                            <div className ="accommodation__config__item__header__title">
                                    <img src={Staff} alt="correct"
                                        className="accommodation__config__item__header-icon" 
                                    />
                                <div className="accommodation__config__item__header-text">
                                    <h3>Staff</h3>
                                </div>
                            </div>
                            {this.props.user && this.props.user.authority === 'ADMIN' ? 
                                <button className="add-button" onClick={this.onShowAddStaffDialog}>
                                    <p>Add new</p>
                                </button>
                            :
                                null
                            }
                        </div>
                        <div className="accommodation__config__users">
                            <div className="accommodation__config__users__scroll">
                                <div className="accommodation__config__users-list">
                                    {this.renderOwners()}
                                    {this.renderStaff()}
                                </div>
                            
                            </div>
                        </div>
                    </div>

                    <div className="accommodation__config__item floor__config">
                        <div className="accommodation__config__item__header">
                            <div className ="accommodation__config__item__header__title">
                                    <img src={Floor} alt="correct"
                                        className="accommodation__config__item__header-icon" 
                                    />
                                <div className="accommodation__config__item__header-text">
                                    <h3>Floors</h3>
                                </div>
                            </div>
                            <button className="add-button" onClick={() => this.openAddDialog('Add new floor')}>
                                <p>Add new</p>
                            </button>
                        </div>
                        <table className="accommodation__config__item-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Rooms</th>
                                    <th style={{width: '16rem'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderFloors()}
                            </tbody>
                        </table>
                    </div>

                    <div className="accommodation__config__item access__config">
                        <div className="accommodation__config__item__header">
                            <div className ="accommodation__config__item__header__title">
                                    <img src={AccessLevelLogo} alt="correct"
                                        className="accommodation__config__item__header-icon" 
                                    />
                                <div className="accommodation__config__item__header-text">
                                    <h3 >Access Levels</h3>
                                </div>
                            </div>
                            <button className="add-button" onClick={() => this.openAddDialog('Add new access level')}>
                                <p>Add new</p>
                            </button>
                        </div>
                        <table className="accommodation__config__item-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{width: '16rem'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderAccessLevels()}
                            </tbody>
                        </table>
                    </div>

                    <div className="accommodation__config__item card__config">
                        <div className="accommodation__config__item__header">
                            <div className ="accommodation__config__item__header__title">
                                    <img src={CardLogo} alt="correct"
                                        className="accommodation__config__item__header-icon" 
                                    />
                                <div className="accommodation__config__item__header-text">
                                    <h3 >Card Types</h3>
                                </div>
                            </div>
                            <button className="add-button" onClick={() => this.openAddDialog('Add new card type')}>
                                <p>Add new</p>
                            </button>
                        </div>
                        
                        <table className="accommodation__config__item-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{width: '16rem'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderCardTypes()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }


    renderFloors = () => {
        if (!this.props.hotel) return null;
        const floors = this.props.hotel.floors.sort((a,b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0)); 
        return floors.map(floor => {
            return (
                <tr key={floor.id}>
                    <td>{floor.name}</td>
                    <td>{floor.rooms.length}</td>
                    <td>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {this.props.user && this.props.user.authority === 'ADMIN' ? 
                                // <button className="remove-button" onClick={() => this.openEditDialog('FLOOR', floor)}>
                                //     <p>Edit</p>
                                // </button>
                                <img src={Edit} alt="edit-icon" className="edit-icon" onClick={() => this.openEditDialog('FLOOR', floor)}></img>
                            :
                                null
                            }
                            <img src={Delete} alt="delte-icon" className="delete-icon" onClick={() => this.openRemoveDialog('FLOOR', floor)}></img>
                            {/* <button className="remove-button" onClick={() => this.openRemoveDialog('FLOOR', floor)}>
                                <p>Remove</p>
                            </button> */}
                        </div>
                    </td>
                </tr>
            )
        });
    }

    renderAccessLevels = () => {
        if (!this.props.hotel) return null;
        const accessLevels = this.props.hotel.accessLevels.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
        return accessLevels.map(accessLevel => {
            return (
                <tr key={accessLevel.id}>
                    <td>{accessLevel.name}</td>
                    <td>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {this.props.user && this.props.user.authority === 'ADMIN' ? 
                                <img src={Edit} alt="edit-icon" className="edit-icon" onClick={() => this.openEditDialog('ACCESS_LEVEL', accessLevel)}></img>
                                :
                                null 
                            }
                            {/* <button className="remove-button" onClick={() => this.openRemoveDialog('ACCESS_LEVEL', accessLevel)}>
                                <p>Remove</p>
                            </button> */}
                                <img src={Delete} alt="delete-icon" className="delete-icon" onClick={() => this.openRemoveDialog('ACCESS_LEVEL', accessLevel)}></img>
                        </div>
                    </td>
                </tr>
            )
        });
    }

    renderCardTypes = () => {
        if (!this.props.hotel) return null;
        const cardTypes = this.props.hotel.cardTypes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
        return cardTypes.map(cardType => {
            return (
                <tr key={cardType.id}>
                    <td>{cardType.name}</td>
                    <td>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {this.props.user && this.props.user.authority === 'ADMIN' ?
                                // <button className="remove-button" onClick={() => this.openEditDialog('CARD_TYPE', cardType)}>
                                //     <p>Edit</p>
                                // </button>
                                <img src={Edit} alt="edit-icon" className="edit-icon" onClick={() => this.openEditDialog('CARD_TYPE', cardType)}></img>
                                :
                                null
                            }
                            {/* <button className="remove-button" onClick={() => this.openRemoveDialog('CARD_TYPE', cardType)}>
                                <p>Remove</p>
                            </button> */}
                                <img src={Delete} alt="delete-icon" className="delete-icon" onClick={() => this.openRemoveDialog('CARD_TYPE', cardType)}></img>
                        </div>
                    </td>
                </tr>
            )
        });
    }

    renderOwners = () => {
        if (!this.props.hotel) return null;
        const staff = this.props.hotel.owners;
        return staff.map(user => {
            let removeObj = {
                id: user.id,
                accommodationId: this.props.hotel.id,
                type: "OWNER"
            }
            return (
                <div className="accommodation__config__users__user" key={user.username}>
                    <div className="accommodation__config__users__user-content">
                        <div className="accommodation__config__users__user-content-left-part">
                            <Avatar size="50" className="accommodation__config__users__user-content-avatar" color={Avatar.getRandomColor('sitebase', ['#31556f', '#31556f', '#31556f'])} name={user.username} round={true} />
                            <div>
                            <div className="accommodation__config__users__user-content-left-part-data">
                                <p className="accommodation__config__users__user-content-left-part-data-email">{user.email}</p>
                            </div>
                                <p className="accommodation__config__users__user-content-left-part-data-type">OWNER</p>
                            </div>
                        </div>
                        <div className="accommodation__config__users__user-content-right-part">
                            <img src={Delete} alt="correct"
                                onClick={() => this.openRemoveDialog('HOTEL_STAFF', removeObj)}
                                className="accommodation__config__users__user-content-right-part-delete" 
                            />
                        </div>
                    </div>
                </div>
            )
        })     
    }

    renderStaff = () => {
        if (!this.props.hotel) return null;
        const staff = this.props.hotel.staff;
        return staff.map(user => {
            let removeObj = {
                id: user.id,
                accommodationId: this.props.hotel.id,
                type: "STAFF"
            }
            return (
                <div className="accommodation__config__users__user" key={user.username}>
                    <div className="accommodation__config__users__user-content">
                        <div className="accommodation__config__users__user-content-left-part">
                            <Avatar size="50" className="accommodation__config__users__user-content-avatar" color={Avatar.getRandomColor('sitebase', ['#31556f', '#31556f', '#31556f'])} name={user.username} round={true} />
                            <div>
                            <div className="accommodation__config__users__user-content-left-part-data">
                                <p className="accommodation__config__users__user-content-left-part-data-email">{user.email}</p>
                            </div>
                                <p className="accommodation__config__users__user-content-left-part-data-type">STAFF</p>
                            </div>
                        </div>
                        <div className="accommodation__config__users__user-content-right-part">
                            <img src={Delete} alt="correct"
                                onClick={() => this.openRemoveDialog('HOTEL_STAFF', removeObj)}
                                className="accommodation__config__users__user-content-right-part-delete" 
                            />
                        </div>
                    </div>
                </div>
            )
        })     
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel.selectedHotel,
    feedbackInfo: state.feedbackInfo,
    user: state.auth.user,
    isFetching: state.feedbackInfo.isFetching
});

const mapDispatchToProps = dispatch => ({
    addNewFloor: newFloor => dispatch(addNewFloor(newFloor)),
    editFloor: floor => dispatch(editFloor(floor)),
    removeFloor: id => dispatch(removeFloor(id)),
    addAccessLevel: newAccessLevel => dispatch(addAccessLevel(newAccessLevel)),
    editAccessLevel: accessLevel => dispatch(editAccessLevel(accessLevel)),
    removeAccessLevel: id => dispatch(removeAccessLevel(id)),
    addCardType: newCardType => dispatch(addCardType(newCardType)),
    editCardType: cardType => dispatch(editCardType(cardType)),
    removeCardType: id => dispatch(removeCardType(id)),
    feedbackResetStatus: () => dispatch(feedbackResetStatus()),
    getHotelById: id => dispatch(getHotelById(id)),
    removeAccomodationStaff: obj => dispatch(removeAccomodationStaff(obj)),
    getHotelLogo: hotel => dispatch(getHotelLogo(hotel)),
    uploadLogo: (hotel,logo) => dispatch(uploadLogo(hotel,logo)),
    updateAccommodation: (updateObj) => dispatch(updateAccommodation(updateObj)),
    feedbackIsFetching: () => dispatch(feedbackIsFetching())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationConfiguration);