import React, { Component } from 'react';
import Logo from '../../../assets/svg/logo/logo-gradient.svg';

class LoadingPage extends Component {
    render() {
        return (
            <div className="pulse__frame">
                <img className="pulse" src={Logo} alt="pulse-logo"/>
            </div>
        )
    }
}

export default LoadingPage;