import React, { Component } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { addNewAccommodation } from '../../../store/actions/hotel';
import { feedbackResetStatus } from '../../../store/actions/feedback';
import Spinner from '../../Utils/Spinner/Spinner';
import { connect } from 'react-redux';
import { validateEmail } from '../../../util/validation';
import CloseIcon from '../../../assets/svg/close.svg';

Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

const INIT_STATE = {
    name: '',
    address: '',
    country: '',
    city: '',
    email: '',
    phoneNumber: '',
    owners: ''
}

class NewAccommodationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    handleChange = event => {
        console.log(event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    validateInput = () => {
        if (this.state.name === '') {
            toast.warning("Accommodation name cannot be empty!");
            return false;
        }

        if (this.state.address === '') {
            toast.warning("Address cannot be empty!");
            return false;
        }

        if (this.state.city === '') {
            toast.warning("City cannot be empty!");
            return false;
        }

        if (this.state.country === '') {
            toast.warning("Country cannot be empty!");
            return false;
        }

        if (!validateEmail(this.state.email)) {
            toast.warning("Email wrong format!");
            return false;
        }

        if (this.state.phoneNumber === '') {
            toast.warning("Phone number cannot be empty!");
            return false; 
        }

        if (this.state.owners === '') {
            toast.warning("Owner's mail cannot be empty!");
            return false;  
        }

        return true;
    }

    onSubmit = event => {
        event.preventDefault();
        console.log('SUBMITTED: ', this.state);
        const validation = this.validateInput();
        if (validation) {
            const newAccommodation = {
                name: this.state.name,
                address: this.state.address,
                country: this.state.country,
                city: this.state.city,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                owners: [this.state.owners]
            }

            this.props.addNewAccommodation(newAccommodation);
        }
    }

    componentDidUpdate() {
        if (this.props.feedbackInfo.isSuccessful === true && this.props.show) {
            toast.success(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
            this.props.onCloseDialog();
        } else if(this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.props.onCloseDialog}
                style={modalStyle}
            >
                <div className="new-accommodation-modal">
                    <div className="new-accommodation-modal__header">
                        <h3>{`Add new accommodation`}</h3>
                        <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={this.props.onCloseDialog} />
                    </div>
                    <div className="new-accommodation-modal__content">
                        <div className="new-accommodation-modal__content__grid">
                            <div className="new-accommodation-modal__content__grid__input full-width">
                                <p>Name</p>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.name} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input full-width">
                                <p>Address</p>
                                <input type="text" name="address" onChange={this.handleChange} value={this.state.address} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input half-width">
                                <p>City</p>
                                <input type="text" name="city" onChange={this.handleChange} value={this.state.city} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input half-width-2">
                                <p>Country</p>
                                <input type="text" name="country" onChange={this.handleChange} value={this.state.country} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input full-width">
                                <p>Email</p>
                                <input type="email" name="email" onChange={this.handleChange} value={this.state.email} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input full-width">
                                <p>Phone number</p>
                                <input type="text" name="phoneNumber" onChange={this.handleChange} value={this.state.phoneNumber} autoComplete="false" />
                            </div>
                            <div className="new-accommodation-modal__content__grid__input full-width">
                                <p>Owner's email:</p>
                                <input type="text" name="owners" onChange={this.handleChange} value={this.state.owners} autoComplete="false" />
                            </div>
                        </div>
                    </div>
                    <div className="new-accommodation-modal__footer">
                        <button className="new-accommodation-modal__footer-btn" onClick={this.onSubmit}>
                            <div>
                                <p>Add</p>
                                {
                                    this.props.feedbackInfo.isFetching ?
                                    <Spinner />
                                    : null
                                }
                            </div>
                        </button>
                        <button 
                            className="new-accommodation-modal__footer-btn-reset" 
                            onClick={() => this.setState(INIT_STATE)}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo
});

const mapDispatchToProps = dispatch => ({
    addNewAccommodation: newAccommodation => dispatch(addNewAccommodation(newAccommodation)),
    feedbackResetStatus: () => dispatch(feedbackResetStatus())
})

export default connect(mapStateToProps, mapDispatchToProps)(NewAccommodationDialog);