import React, { Fragment } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { connect } from "react-redux";
import { getRoomById, setRoomToNull } from "../../store/actions/room";
import {
  postReservation,
  updateReservation,
  cancelReservation,
  unlockPin,
} from "../../store/actions/reservations";
import { feedbackResetStatus } from "../../store/actions/feedback";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { validateEmail } from "../../util/validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../Utils/Spinner/Spinner";
import CloseIcon from "../../assets/svg/close.svg";
import Unlock from "../../assets/svg/unlock.svg";
import Locked from "../../assets/svg/lock.svg";
import AriaModal from "react-aria-modal";


const animatedComponents = makeAnimated();

Modal.setAppElement("#root");

const INIT_STATE = {
  showAddReservationModal: false,
  showDeleteReservationModal: false,
  startDate: null,
  endDate: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  accessLevel: null,
  cardType: null,
  floorAccess: null,
  note: "",
  selectedEventID: null,
  isPinLocked: null,
  unlocked: null,
  showStartDatePicker: false,
  showEndDatePicker: false,
};

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = INIT_STATE;
  }

  componentDidMount() {
    const arr = window.location.href.split("/");
    this.props.getRoomById(arr[arr.length - 1]);
    if (this.props.hotel) {
      this.setState({
        accessLevel: this.props.hotel.accessLevels[0].id + "",
        cardType: this.props.hotel.cardTypes[0].id + "",
      });
    }
  }

  componentWillUnmount() {
    this.props.setRoomToNull();
  }

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    if (selectInfo.allDay) {
      var yesterday = new Date(selectInfo.end.getTime());
      yesterday.setDate(yesterday.getDate() - 1);
      const arrStart = formatDate(selectInfo.start, {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      }).split("/");
      selectInfo.startStr = arrStart[2] + "-" + arrStart[0] + "-" + arrStart[1];
      const arrEnd = formatDate(yesterday, {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      }).split("/");
      selectInfo.endStr = arrEnd[2] + "-" + arrEnd[0] + "-" + arrEnd[1];
    }

    // By default 12h
    const startDate = selectInfo.allDay
      ? selectInfo.startStr + "T12:00:00+02:00"
      : selectInfo.startStr;
    const endDate = selectInfo.allDay
      ? selectInfo.endStr + "T12:00:00+02:00"
      : selectInfo.endStr;

    this.setState({
      startDate,
      endDate,
      showAddReservationModal: true,
    });
  };

  handleEventClick = (clickInfo) => {
    let id = clickInfo.event.id;
    let reservation;
    this.props.room.reservations.forEach((res) => {
      if (res.id + "" === id) {
        reservation = res;
      }
    });
    this.setState({
      showDeleteReservationModal: true,
      selectedEventID: clickInfo.event.id,
      isPinLocked: reservation.cardTaken,
      pin: reservation.cardId,
    });
  };

  handleEventDrag = (info) => {
    console.log("info", info.event.id);
    console.log("new dates", info.event._instance.range);
    this.props.updateReservation({
      id: info.event.id,
      startDate: info.event._instance.range.start,
      endDate: info.event._instance.range.end,
    });
  };

  handleEventResize = (info) => {
    console.log("resize info", info.event.id);
    console.log("new dates resize", info.event._instance.range);
    this.props.updateReservation({
      id: info.event.id,
      startDate: info.event._instance.range.start,
      endDate: info.event._instance.range.end,
    });
  };

  renderAccessLevels = () => {
    if (!this.props.hotel) return null;
    return this.props.hotel.accessLevels.map((accessLevel) => {
      return (
        <option key={accessLevel.id} value={accessLevel.id}>
          {accessLevel.name}
        </option>
      );
    });
  };

  renderCardTypes = () => {
    if (!this.props.hotel) return null;
    return this.props.hotel.cardTypes.map((cardType) => {
      return (
        <option key={cardType.id} value={cardType.id}>
          {cardType.name}
        </option>
      );
    });
  };

  renderFloorAccess = () => {
    if (!this.props.hotel) return null;
    return this.props.hotel.floors.map((floor) => {
      return (
        <option key={floor.id} value={floor.id}>
          {floor.name}
        </option>
      );
    });
  };

  renderAriaModal = (options) => {
    return (
      <AriaModal
        isOpen={this.state.showAddReservationModal}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        titleText="Check in"
        onExit={() => this.setState({ showAddReservationModal: false })}
        initialFocus="#root"
        dialogStyle={{ borderRadius: "1.5rem" }}
        underlayStyle={{ paddingTop: "2em" }}
        onRequestClose={() => this.setState({ showAddReservationModal: false })}
      >
        <div className="modal">
          <div className="modal__header">
            <h3>CHECK-IN</h3>
            <img
              src={CloseIcon}
              style={{ width: "2.2rem", height: "2.2rem" }}
              alt="close-btn"
              onClick={() => this.setState({ showAddReservationModal: false })}
            />
          </div>
          <div className="modal__content">
            <p className="modal__content-title">{`Room ${
              this.props.room && this.props.room.name
            }`}</p>
            <div className="modal__content__grid">
              <div className="modal__content__grid__input half-width">
                <p>First Name</p>
                <input
                  type="text"
                  name="firstName"
                  onChange={this.handleChange}
                  value={this.state.firstName}
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input half-width-2">
                <p>Last Name</p>
                <input
                  type="text"
                  name="lastName"
                  onChange={this.handleChange}
                  value={this.state.lastName}
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input full-width">
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input full-width">
                <p>Phone number</p>
                <input
                  type="text"
                  name="phoneNumber"
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input half-width">
                <p>From</p>
                <input
                  type="date"
                  name="startDate"
                  onChange={this.handleChange}
                  value={
                    this.state.startDate
                      ? this.state.startDate.split("T")[0]
                      : null
                  }
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input half-width-2">
                <p>Until</p>
                <input
                  type="date"
                  name="endDate"
                  onChange={this.handleChange}
                  value={
                    this.state.endDate ? this.state.endDate.split("T")[0] : null
                  }
                  autoComplete="false"
                />
              </div>
              <div className="modal__content__grid__input full-width">
                <p>Floor access</p>
                {/* <select name="floorAccess" onChange={this.handleChange} value={this.state.floorAccess}>
                                        {this.renderFloorAccess()}
                                    </select> */}
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={options}
                  value={this.state.floorAccess}
                  onChange={this.handleChangeSelect}
                />
              </div>
              <div className="modal__content__grid__input half-width">
                <p>Access level</p>
                <select
                  name="accessLevel"
                  onChange={this.handleChange}
                  value={this.state.accessLevel}
                >
                  {this.renderAccessLevels()}
                </select>
              </div>
              <div className="modal__content__grid__input half-width-2">
                <p>Card type</p>
                <select
                  name="cardType"
                  onChange={this.handleChange}
                  value={this.state.cardType}
                >
                  {this.renderCardTypes()}
                </select>
              </div>
              <div className="modal__content__grid__input full-width">
                <p>Note</p>
                <textarea
                  name="note"
                  value={this.state.note}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <button className="modal__footer-btn" onClick={this.onReserve}>
              <div>
                <p>Reserve</p>
                {this.props.feedbackInfo.isFetching ? <Spinner /> : null}
              </div>
            </button>
            <button
              className="modal__footer-btn-reset"
              onClick={() =>
                this.setState({
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  note: "",
                })
              }
            >
              Reset
            </button>
          </div>
        </div>
      </AriaModal>
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeSelect = (selectedOption) => {
    this.setState({
      floorAccess: selectedOption,
    });
  };

  validateOnReserveInput = () => {
    if (this.state.firstName === "") {
      toast.warning("First name cannot be empty!");
      return false;
    }

    if (this.state.lastName === "") {
      toast.warning("Last name cannot be empty!");
      return false;
    }

    if (this.state.email === "") {
      toast.warning("Email cannot be empty!");
      return false;
    }

    if (!validateEmail(this.state.email)) {
      toast.warning("Email wrong format!");
      return false;
    }

    if (this.state.phoneNumber === "") {
      toast.warning("Phone number cannot be empty!");
      return false;
    }

    if (this.state.floorAccess === null) {
      toast.warning("Add at least 1 floor access!");
      return false;
    }

    if (
      this.state.floorAccess !== null &&
      this.state.floorAccess.length === 0
    ) {
      toast.warning("Add at least 1 floor access!");
      return false;
    }

    if (isNaN(this.state.accessLevel)) {
      toast.warning("Access level id is not a number!");
      return false;
    }

    if (isNaN(this.state.cardType)) {
      toast.warning("Card type id is not a number!");
      return false;
    }

    return true;
  };

  onReserve = (event) => {
    event.preventDefault();
    const validation = this.validateOnReserveInput();
    if (validation) {
      const floorIds = this.state.floorAccess.map(
        (floorAccess) => floorAccess.value
      );
      const newReservation = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        email: this.state.email,
        fullName: this.state.firstName + " " + this.state.lastName,
        roomId: this.props.room.id,
        accessLevelId: parseInt(this.state.accessLevel, 10),
        cardTypeId: parseInt(this.state.cardType, 10),
        note: this.state.note,
        phoneNumber: this.state.phoneNumber,
        floorIds,
      };
      this.props.postReservation(newReservation);
    }
  };

  onDeleteReservation = () => {
    this.props.cancelReservation(this.state.selectedEventID);
  };

  onUnlockPin = () => {
    this.setState({
      unlocked: true,
    });
    setTimeout(() => {
      this.props.unlockPin(this.state.selectedEventID);
    }, 1500);
  };

  onCloseDeleteReservationModal = () => {
    this.setState({
      showDeleteReservationModal: false,
      selectedEventID: null,
    });
  };

  componentDidUpdate() {
    if (this.props.feedbackInfo.isSuccessful === true) {
      toast.success(this.props.feedbackInfo.message);
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        note: "",
        showAddReservationModal: false,
        showDeleteReservationModal: false,
        floorAccess: null,
        unlocked: null,
      });
      this.props.feedbackResetStatus();
    } else if (this.props.feedbackInfo.isSuccessful === false) {
      toast.error(this.props.feedbackInfo.message);
      this.props.feedbackResetStatus();
    }
  }

  renderPinPart = () => {
    if (this.state.isPinLocked && this.state.unlocked) {
      return (
        <div className="lockedPinDiv">
          <p>Unlocked</p>
          <img src={Unlock} className="lockicon" alt="lock"></img>
        </div>
      );
    }
    if (this.state.isPinLocked) {
      return (
        <div className="lockedPinDiv">
          <p>This pin is locked, click to unlock</p>
          <img
            src={Locked}
            className="lockicon"
            alt="lock"
            onClick={this.onUnlockPin}
          ></img>
        </div>
      );
    }
  };

  render() {
    var roomReservations = [];
    if (this.props.room) {
      roomReservations = this.props.room.reservations.map((reservation) => {
        return {
          id: reservation.id,
          title: reservation.fullName,
          start: reservation.startDate,
          end: reservation.endDate,
          isPinLocked: reservation.cardTaken,
          pin: reservation.cardId,
          allDay: false,
        };
      });
    }
    const options = [];
    if (this.props.hotel) {
      const floors = this.props.hotel.floors.sort((a, b) =>
        a.level > b.level ? 1 : b.level > a.level ? -1 : 0
      );
      floors.forEach((floor) => {
        options.push({ value: floor.id, label: floor.name });
      });
    }

    return (
      <Fragment>
        <Modal
          isOpen={this.state.showDeleteReservationModal}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.onCloseDeleteReservationModal()}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
              zIndex: 5,
            },
            content: {
              top: "10%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, 0%)",
              width: "45rem",
              borderRadius: "1.5rem",
              padding: 0,
            },
          }}
        >
          <div className="modal">
            <div className="modal__header">
              <h3>REMOVE RESERVATION OR UNLOCK PIN</h3>
              <img
                src={CloseIcon}
                style={{ width: "2.2rem", height: "2.2rem" }}
                alt="close-btn"
                onClick={() => this.onCloseDeleteReservationModal()}
              />
            </div>
            <div className="modal__content">
              <p className="modal__content-title">
                {"Are you sure you want to remove reservation?"}
              </p>
              <p className="modal__content-title">PIN: {this.state.pin}</p>
              {this.renderPinPart()}
            </div>
            <div className="modal__footer__2">
              <button
                className="modal__footer__2-btn-reset"
                onClick={() => {
                  this.onDeleteReservation();
                }}
              >
                <div>
                  <p>Remove</p>
                  {this.props.feedbackInfo.isFetching ? <Spinner /> : null}
                </div>
              </button>
              <button
                className="modal__footer__2-btn"
                onClick={() => this.onCloseDeleteReservationModal()}
              >
                <p>Cancel</p>
              </button>
            </div>
          </div>
        </Modal>
        {this.state.showAddReservationModal
          ? this.renderAriaModal(options)
          : null}
        <div className="room__info">
          {this.props.room ? <h3>{`Room ${this.props.room.name}`}</h3> : null}
        </div>
        <div className="room">
          <div className="room__calendar">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                right: "dayGridMonth,timeGridWeek",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={true}
              eventDragStop={(info) => console.log(info.event)}
              eventDrop={this.handleEventDrag}
              events={roomReservations}
              eventResize={this.handleEventResize}
              select={this.handleDateSelect}
              eventContent={renderEventContent}
              eventClick={this.handleEventClick}
              // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
                            eventAdd={function(){}}
                            eventChange={function(){}}
                            eventRemove={function(){}}
                            */
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function renderEventContent(eventInfo) {
  return (
    <div className="event-title">
      <p>{eventInfo.event.title}</p>
    </div>
  );
}

const mapStateToProps = (state) => ({
  room: state.hotel.selectedRoom,
  hotel: state.hotel.selectedHotel,
  feedbackInfo: state.feedbackInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getRoomById: (id) => dispatch(getRoomById(id)),
  setRoomToNull: () => dispatch(setRoomToNull()),
  postReservation: (reservation) => dispatch(postReservation(reservation)),
  cancelReservation: (id) => dispatch(cancelReservation(id)),
  feedbackResetStatus: () => dispatch(feedbackResetStatus()),
  unlockPin: (id) => dispatch(unlockPin(id)),
  updateReservation: (data) => dispatch(updateReservation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
