import React, { Component, Fragment } from 'react';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import PrivateRoute from '../PrivateRoute';
import AccommodationOverviewComponent from '../../Accommodation/Overview/AccommodationOverview';
import AccommodationConfigComponent from '../../Accommodation/Config/AccommodationConfiguration';
import DashboardComponent from '../../Dashboard/Dashboard';
import Room from '../../Room/Room';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import LoginPage from '../../Auth/LoginPage';
import history from '../../../history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Devices from '../../Devices/Devices';
import Users from '../../Users/Users';
import Account from '../../Account/Account';

class Container extends Component {

    componentDidMount() {
        if (this.props.isLoggedIn === true) {
            history.push('/dashboard');
        } else if (this.props.isLoggedIn === false){
            history.push('/login');
        }
    }

    

    render() {
        const className = this.props.isLoggedIn ? "container" : null;
        return (
            <Fragment>
                <ToastContainer newestOnTop={true} />
                <Route exact path="/login" component={LoginPage} />
                <div className={className}>
                    <Header />
                    <Sidebar />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/dashboard' exact component={DashboardComponent} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/devices' exact component={Devices} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/room/:id' component={Room} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/accommodation/overview/:id' component={AccommodationOverviewComponent} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/users' component={Users} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/account' component={Account} />
                    <PrivateRoute authed={this.props.isLoggedIn} path='/accommodation/configuration/:id' component={AccommodationConfigComponent} />
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, null)(Container);