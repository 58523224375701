import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getAllUsers } from '../../../../store/actions/auth';
import {addAcoomodationStaff} from '../../../../store/actions/hotel';
import CloseIcon from '../../../../assets/svg/close.svg';
import Avatar from 'react-avatar';

Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

class AddStaffDialog extends Component {

    componentDidMount(){
        if (this.props.user !== null) {
            if (this.props.user.authority === 'ADMIN') {
                this.props.getAllUsers();
            }
        } 
    }

    onAddHotelStaff = (user) =>{
        const staffAddingDTO = {
            accommodationId: this.props.hotel.id,
            id: user.id,
            type: user.type
        }

        this.props.addAcoomodationStaff(staffAddingDTO);
    }

    remainingStaffToAdd = () =>{
        if (!this.props.hotelStaff) return null;
        if (!this.props.allUsers) return null;

        let renderingUsers = [];
        this.props.allUsers.forEach(user => {
            let contains = false;
            this.props.hotelStaff.forEach(u => {
                if(user.id === u.id){
                    contains = true;
                }
            });
            if(contains === false){
                renderingUsers.push(user);
            }
        });
        return renderingUsers.length;
    }


    renderOtherStaff = () => {
        if (!this.props.hotelStaff) return null;
        if (!this.props.allUsers) return null;

        let renderingUsers = [];
        this.props.allUsers.forEach(user => {
            let contains = false;
            this.props.hotelStaff.forEach(u => {
                if(user.id === u.id){
                    contains = true;
                }
            });
            if(contains === false){
                renderingUsers.push(user);
            }
        });

        return renderingUsers.map(user => {
            return (
                <div className="add-staff-config__modal__users__user" key={user.username}>
                    <div className="add-staff-config__modal__users__user-content">
                        <div className="add-staff-config__modal__users__user-content-left-part">
                            <Avatar size="50" className="add-staff-config__modal__users__user-content-avatar" color={Avatar.getRandomColor('sitebase', ['#4c66b0', '#e34043', '#4c66b0'])} name={user.email} round={true} />
                            <div>
                            <div className="add-staff-config__modal__users__user-content-left-part-data">
                                <p className="add-staff-config__modal__users__user-content-left-part-data-email">{user.email}</p>
                            </div>
                                <p className="add-staff-config__modal__users__user-content-left-part-data-type">{user.type}</p>
                            </div>
                        </div>
                        <div className="add-staff-config__modal__users__user-content-right-part">
                            <button className="users-button" onClick={() => this.onAddHotelStaff(user)}>
                                <p>Add</p>
                            </button>
                        </div>
                    </div>
                </div>
            )
        });    
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                shouldCloseOnOverlayClick={true}
                hotelStaff ={this.props.hotelStaff}
                onRequestClose={this.props.onClose}
                style={modalStyle}
            >
                <div className="add-staff-config__modal">
                    <div className="add-config__modal__header">
                        {   this.remainingStaffToAdd() === 0 ?
                            <div>
                                <h3>No new staff to add</h3>
                            </div>
                            :
                            <h3>{this.props.title}</h3>
                        }
                        <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={this.props.onClose} />
                    </div>
                    <div className="add-staff-config__modal__content">
                        <div className="add-staff-config__modal__users">
                            <div className="add-staff-config__modal__users__scroll">
                                <div className="add-staff-config__modal__users__users-list">
                                    {this.renderOtherStaff()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo,
    allUsers: state.auth.allUsers,
    user: state.auth.user
});

export default connect(mapStateToProps, {getAllUsers, addAcoomodationStaff})(AddStaffDialog);