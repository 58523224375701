import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { logout } from '../../store/actions/auth';
import HomeIcon from '../../assets/svg/sidebar/1.svg';
import ConfigIcon from '../../assets/svg/sidebar/5.svg';
import LogoutIcon from '../../assets/svg/sidebar/7.svg';
import LogoGradientIcon from '../../assets/svg/logo/logo-gradient.svg';
import DashboardIcon from '../../assets/svg/sidebar/kvadrat.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {selected: 'dashboard'}
    }

    redirectToOverview = () => {
        if (this.props.selectedHotel) history.push(`/accommodation/overview/${this.props.selectedHotel.id}`);
    }

    redirectToConfiguration = () => {
        if (this.props.selectedHotel) history.push(`/accommodation/configuration/${this.props.selectedHotel.id}`);
    }

    render() {
        if (!this.props.isLoggedIn) return null;
        return (
            <div className="sidebar">
                <img 
                    onClick={() => history.push('/dashboard')} 
                    className="sidebar__logo" 
                    src={LogoGradientIcon}
                    alt="logo" />
                <div className="sidebar__icons">
                    <div className="sidebar__icons__item" onClick={() => history.push('/dashboard')}>
                        <img src={DashboardIcon} alt="dashboard" />
                    </div>
                    <div className="sidebar__icons__item" onClick={() => this.redirectToOverview()}>
                        <img src={HomeIcon} alt="home" />
                    </div>
                    <div className="sidebar__icons__item" onClick={() => this.redirectToConfiguration()}>
                        <img src={ConfigIcon} alt="config" />
                    </div>
                </div>
                <div className="sidebar__icons__item">
                <img src={LogoutIcon} alt="logout" onClick={() => this.props.logout()} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    selectedHotel: state.hotel.selectedHotel
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);