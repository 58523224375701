import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../../history';
import { getUserInfo } from '../../store/actions/auth';
import '../sass/main.scss';
import AppLoader from '../Utils/AppLoader';

class App extends Component {

    constructor(props) {
        super(props);
        this.props.getUserInfo();
    }

    render() {
        return (
            <Router history={history}>
                <AppLoader minimumLoadingTime={1000} />
            </Router>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getUserInfo: () => dispatch(getUserInfo())
});

export default connect(null, mapDispatchToProps)(App);