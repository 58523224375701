import React, { Component } from 'react';
import Cropper from 'react-easy-crop'
import { connect } from 'react-redux';
import { uploadLogo } from '../../store/actions/hotel';
import { feedbackIsFetching } from '../../store/actions/feedback';

class ImageCrop extends Component {

    constructor(){
        super();
        this.state = {
            image: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
        }
    }
    
    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels
        })

    }

    handleClick = () => {
        const crop = {
            x: this.state.croppedAreaPixels.x,
            y: this.state.croppedAreaPixels.y,
            w: this.state.croppedAreaPixels.width,
            h: this.state.croppedAreaPixels.height,

        }
        this.props.feedbackIsFetching();
        this.props.uploadLogo(this.props.imageUri,this.props.hotel,crop);  
        this.props.close();
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    render() {
        return (
            <div className="crop">
                <div className="crop-container">
                    <Cropper
                        image={this.props.image}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                </div>
                <div className="controls">
                    <button onClick={this.handleClick} className="finish-crop">Upload</button>
                    <button onClick={this.props.close} className="cancel-crop">Cancel</button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    uploadLogo: (hotel, logo, crop) => dispatch(uploadLogo(hotel, logo, crop)),
    feedbackIsFetching: () => dispatch(feedbackIsFetching())
})

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo,
    isFetching: state.feedbackInfo.isFetching
});


export default connect(mapStateToProps, mapDispatchToProps)(ImageCrop);