import {
  LOGIN,
  LOGOUT,
  USER_NOT_FOUND,
  USERS_DATA,
  ALL_USER,
  USER_ADDED,
  USER_DELTED,
} from "../constants";
import apiBase from "../../apis/api";
import {
  feedbackSuccessful,
  feedbackFailed,
  feedbackIsFetching,
} from "./feedback";
import { parseJwt } from "../../util/util";

export const login = (user) => {
  return async (dispatch) => {
    try {
      const response = await apiBase.post("/auth", user);
      if (response.status !== 200) throw new Error();
      const token = response.data.token;
      localStorage.setItem("authToken", token);
      dispatch({ type: LOGIN, payload: token });
    } catch (e) {
      console.log(e.message);
    }
  };
};

export const logout = () => {
  localStorage.removeItem("authToken");
  return { type: LOGOUT };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error();
      const headers = { headers: { Authorization: token } };
      const response = await apiBase.get("/users/me", headers);
      if (response.status !== 200 || response.data === null) throw new Error();
      dispatch({ type: LOGIN, payload: token });
      const now = Date.now();
      const jwt = parseJwt(token);
      const interval = jwt.exp * 1000 - now;
      console.log(interval);
      setTimeout(() => getUserInfo(), interval);
      dispatch({ type: USERS_DATA, payload: response.data });
    } catch (e) {
      dispatch({ type: USER_NOT_FOUND });
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error();
      const headers = { headers: { Authorization: token } };
      const response = await apiBase.get("/users", headers);
      if (response.status !== 200 || response.data === null) throw new Error();
      dispatch({ type: ALL_USER, payload: response.data });
    } catch (e) {}
  };
};

export const addUser = (user) => {
  return async (dispatch) => {
    dispatch(feedbackIsFetching());
    let response = null;
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error();
      const headers = { headers: { Authorization: token } };
      response = await apiBase.post("/users/staff", user, headers);
      if (response.status !== 201) throw new Error();
      dispatch(feedbackSuccessful("New User added successfully!"));
      dispatch({ type: USER_ADDED, payload: response.data });
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Error while adding new user!"
        )
      );
    }
  };
};

export const changePassword = (password) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error();
      const headers = { headers: { Authorization: token } };
      await apiBase.put(`/users/password/${password}`, null, headers);
      dispatch(feedbackSuccessful("Password changed successfully!"));
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Error while adding new user!"
        )
      );
    }
  };
};

export const deleteUser = (user) => {
  return async (dispatch) => {
    let response = null;
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error();
      const headers = { headers: { Authorization: token } };
      response = await apiBase.delete(
        `users/staff/${user.id}`,
        headers,
        headers
      );
      if (response.status !== 200) throw new Error();
      dispatch(feedbackSuccessful("User deleted successfully!"));
      dispatch({ type: USER_DELTED, payload: user });
    } catch (e) {
      dispatch(
        feedbackFailed(
          e.response.data.message || "Error while deleting new user!"
        )
      );
    }
  };
};
