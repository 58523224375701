import React, { Component } from 'react';
import { getHotelById } from '../../../store/actions/hotel';
import { connect } from 'react-redux';
import history from '../../../history';
import ArrowDownIcon from '../../../assets/svg/arrow-down-2.svg';

class HotelSelector extends Component {

    getHotelInfo = id => {
        this.props.getHotelById(id);
        const middleUrl = window.location.href.includes('configuration/') ? 'configuration' : 'overview';
        history.push(`/accommodation/${middleUrl}/${id}`);
    }

    renderAccommodations = () => {
        if (!this.props.hotels || !this.props.hotel) return null;
        if (this.props.hotels.length === 1) {
            return <button style={{borderRadius: '1rem'}} onClick={() => {this.props.getHotelById(this.props.hotel.id)}}>{this.props.hotel.name}</button>
        }
        const hotels = this.props.hotels.filter(hotel => hotel.id === this.props.hotel.id);
        this.props.hotels.forEach(hotel => {
            if(hotel.id !== this.props.hotel.id) {
                hotels.push(hotel);
            }
        });

        return hotels.map(hotel => {
            return <button key={hotel.id} onClick={() => this.getHotelInfo(hotel.id)}>{hotel.name}</button>
        })
    }

    render() {
        return (
            <div className="current-hotel">
                <div className="top-dropdown">
                    <div className="top-dropdown-dd">
                        <div className="top-dropdown-hotel">
                            <h4>{this.props.hotel ? this.props.hotel.name : null}</h4>
                            <img src={ArrowDownIcon} alt="arrow-down" style={{width: '1.05rem', height: '1.05rem'}} />
                        </div>
                        <p className="top-dropdown-address">{this.props.hotel ? this.props.hotel.address : null}</p>
                    </div>
                    <div className="top-dropdown-content">
                        {this.renderAccommodations()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel.selectedHotel,
    hotels: state.hotel.hotels
});

const mapDispatchToProps = dispatch => ({
    getHotelById: id => dispatch(getHotelById(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(HotelSelector);