import React, { Component } from 'react';
import Modal from 'react-modal';
import Spinner from '../../Utils/Spinner/Spinner';
import { connect } from 'react-redux';
import { validateEmail } from '../../../util/validation';
import { toast } from 'react-toastify';
import { addUser } from '../../../store/actions/auth';
import CloseIcon from '../../../assets/svg/close.svg';

const INIT_STATE = {
    options: [
        {
            id: 1,
            name: "OWNER"
        },
        {
            id: 2,
            name: "STAFF"
        }
    ],
    username: "",
    email: "",
    title: "",
    type: "OWNER"
}

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

class AddUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    handleChange = event => {
        this.setState({
            type: event.target.value
        });
    }

    renderTypes = () => {
        return this.state.options.map(option => {
            return <option key={option.id} value={option.name}>{option.name}</option>
        });
    };

    validateOnAddClick = () => {
        if (this.state.username === '') {
            toast.warning("Username cannot be empty!");
            return false;
        }

        if (this.state.email === '') {
            toast.warning("Email cannot be empty!");
            return false;
        }
        if (!validateEmail(this.state.email)) {
            toast.warning("Email wrong format!");
            return false;
        }
        return true;
    }

    onAddUserClick = () => {
        if(this.validateOnAddClick() === false){
            return;
        }
        const user = {
            email: this.state.email,
            username: this.state.username,
            type: this.state.type
        }
        this.props.addUser(user);
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showAddDialog}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => this.props.closeAddDialog()}
                style={ modalStyle }
            >
                <div className="add-new-user-config__modal">
                    <div className="add-new-user-config__modal__header">
                        <h3>{this.props.title}</h3>
                        <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={() => this.props.closeAddDialog()} />
                    </div>
                    <div className="add-new-user-config__modal__content">
                        <div className="add-new-user-config__modal__content__input">
                            <div className="add-new-user-config__modal__content__input-title">
                                <p>Username: </p>
                            </div>
                            <input type="text" name="name" onChange={e => this.setState({username: e.target.value})} value={this.state.username} autoComplete="false" />
                        </div>
                        <div className="add-new-user-config__modal__content__input">
                            <div className="add-new-user-config__modal__content__input-title">
                                <p>Email: </p>
                            </div>
                            <input type="text" name="name" onChange={e => this.setState({email: e.target.value})} value={this.state.email} autoComplete="false" />
                        </div>
                        <div className="add-new-user-config__modal__content__grid__input half-width">
                            <p>Access level</p>
                            <select name="accessLevel" onChange={this.handleChange} value={this.state.type}>
                                {this.renderTypes()}
                            </select>
                        </div>
                    </div>
                    <div className="add-new-user-config__modal__footer">
                        <button className="add-new-user-config__modal__footer-btn" onClick={this.onAddUserClick}>
                            <div>
                                <p>Add User</p>
                                {
                                    this.props.feedbackInfo.isFetching === true ? 
                                    <Spinner />
                                    :
                                    null
                                }
                            </div>
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addUser: (user) => dispatch(addUser(user)),
});

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo
});


export default connect(mapStateToProps,mapDispatchToProps)(AddUserModal);