import apiBase from '../../apis/api';
import { ADD_NEW_FLOOR, ADD_NEW_ACCESS_LEVEL, ADD_NEW_CARD_TYPE, REMOVE_FLOOR,
    REMOVE_ACCESS_LEVEL, REMOVE_CARD_TYPE, EDIT_FLOOR, EDIT_ACCESS_LEVEL, EDIT_CARD_TYPE } from '../constants';
import { feedbackSuccessful, feedbackFailed, feedbackIsFetching } from './feedback';
import { makeHeader } from './base';

// Floors
export const addNewFloor = newFloor => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.post(`/floor`, newFloor, headers);
            if (response.status !== 200 && response.status !== 201) throw new Error();
            dispatch(feedbackSuccessful('New floor added successfully!'))
            dispatch({ type: ADD_NEW_FLOOR, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new floor!'));
        }
    }
}

export const editFloor = floor => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.put(`/floor`, floor, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Floor updated successfully!'));
            dispatch({ type: EDIT_FLOOR, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while updating floor!'));
        }
    }
}

export const removeFloor = id => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.delete(`/floor/${id}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Floor removed successfully!'));
            dispatch({ type: REMOVE_FLOOR, payload: id });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while removing floor!'));
        }
    }
}

// Access Level
export const addAccessLevel = newAccessLevel => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.post(`/access-level`, newAccessLevel, headers);
            if (response.status !== 200 && response.status !== 201) throw new Error();
            dispatch(feedbackSuccessful('New access level added successfully!'));
            dispatch({ type: ADD_NEW_ACCESS_LEVEL, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new access level!'));
        }
    }
}

export const editAccessLevel = accessLevel => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.put(`/access-level`, accessLevel, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Access level updated successfully!'));
            dispatch({ type: EDIT_ACCESS_LEVEL, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while updating access level!'));
        }
    }
}

export const removeAccessLevel = id => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.delete(`/access-level/${id}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Access level removed successfully!'));
            dispatch({ type: REMOVE_ACCESS_LEVEL, payload: id });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while removing access level!'));
        }
    }
}


// Card type
export const addCardType = newCardType => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.post(`/card-type`, newCardType, headers);
            if (response.status !== 200 && response.status !== 201) throw new Error();
            dispatch(feedbackSuccessful('New card type added successfully!'));
            dispatch({ type: ADD_NEW_CARD_TYPE, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while adding new card type!'));
        }
    }
}

export const editCardType = cardType => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.put(`/card-type`, cardType, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Card type updated successfully!'));
            dispatch({ type: EDIT_CARD_TYPE, payload: response.data });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while updating card type!'));
        }
    }
}

export const removeCardType = id => {
    return async dispatch => {
        try {
            dispatch(feedbackIsFetching());
            const headers = makeHeader();
            const response = await apiBase.delete(`/card-type/${id}`, headers);
            if (response.status !== 200) throw new Error();
            dispatch(feedbackSuccessful('Card type removed successfully!'));
            dispatch({ type: REMOVE_CARD_TYPE, payload: id });
        } catch (e) {
            dispatch(feedbackFailed(e.response.data.message || 'Error while removing card type!'));
        }
    }
}