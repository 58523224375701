import { FEEDBACK_SUCCESSFUL, FEEDBACK_FAILED, FEEDBACK_RESET_STATUS, FEEDBACK_IS_FETCHING } from "../constants";

export const feedbackSuccessful = message => {
    return { type: FEEDBACK_SUCCESSFUL, payload: message };
}

export const feedbackFailed = message => {
    return { type: FEEDBACK_FAILED, payload: message };
}

export const feedbackResetStatus = () => {
    return { type: FEEDBACK_RESET_STATUS };
}

export const feedbackIsFetching = () => {
    return { type: FEEDBACK_IS_FETCHING };
}