import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewDeviceConfiguration } from '../../store/actions/devices';
import OnlineIcon from '../../assets/svg/checked.svg';
import OfflineIcon from '../../assets/svg/wrong.svg';
import SettingsIcon from '../../assets/svg/settings.svg';
import { toast } from 'react-toastify';

const INIT_STATE = {
    showLogs: false,
    showEditDialog: false,
    serverHost: '',
    modbusHost: '',
    modbusPort: '',
    roomIdRegister: '',
    floorIdRegister: '',
    cardTypeRegister: '',
    accessLevelRegisterLo: '',
    accessLevelRegisterHi: '',
    writeCommandRegister: '',
    dispenserStatusRegister: '',
    yearRegister: '',
    monthRegister: '',
    dayRegister: '',
    dispenserStatusErrorRegister: ''
}

class DeviceDetail2 extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    componentDidMount() {
        if (this.props.activeConfig) {
            this.updateDeviceState(this.props.activeConfig);
        }
    }

    toggleLogs = () => {
        this.setState({ showLogs: !this.state.showLogs })
    }

    showEdit = () => {
        this.setState({ showEditDialog: !this.state.showEditDialog })
    }

    updateDeviceState = activeConfig => {
        const {serverHost, modbusHost, modbusPort, roomIdRegister, floorIdRegister, cardTypeRegister,
            accessLevelRegisterLo, accessLevelRegisterHi, writeCommandRegister, dispenserStatusRegister,
            yearRegister, monthRegister, dayRegister, dispenserStatusErrorRegister} = activeConfig;
        this.setState({
            serverHost,
            modbusHost,
            modbusPort,
            roomIdRegister,
            floorIdRegister,
            cardTypeRegister,
            accessLevelRegisterLo,
            accessLevelRegisterHi,
            writeCommandRegister,
            dispenserStatusRegister,
            yearRegister,
            monthRegister,
            dayRegister,
            dispenserStatusErrorRegister
        });
    }

    compare = (a, b) => {
        if (a.timestamp > b.timestamp) return 1;
        if (a.timestamp < b.timestamp) return -1;
        return 0;
    }

    updateConfiguration = event => {
        event.preventDefault();
        event.stopPropagation();
        const validation = this.validate();
        if (validation) {
            const config = {
                body: {
                    modbusHost: this.state.modbusHost,
                    serverHost: this.state.serverHost,
                    modbusPort: this.state.modbusPort,
                    roomIdRegister: this.state.roomIdRegister,
                    floorIdRegister: this.state.floorIdRegister,
                    cardTypeRegister: this.state.cardTypeRegister,
                    accessLevelRegisterLo: this.state.accessLevelRegisterLo,
                    accessLevelRegisterHi: this.state.accessLevelRegisterHi,
                    writeCommandRegister: this.state.writeCommandRegister,
                    dispenserStatusRegister: this.state.dispenserStatusRegister,
                    yearRegister: this.state.yearRegister,
                    monthRegister: this.state.monthRegister,
                    dayRegister: this.state.dayRegister,
                    dispenserStatusErrorRegister: this.state.dispenserStatusErrorRegister,
                },
                deviceId: this.props.device.id
            }
            
            this.setState({showEditDialog: false}, () => {
                this.props.addNewDeviceConfiguration(config);
            });
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    validate = () => {
        const elems = ['serverHost', 'modbusHost', 'modbusPort', 'roomIdRegister', 'floorIdRegister',
            'cardTypeRegister', 'accessLevelRegisterLo', 'accessLevelRegisterHi', 'writeCommandRegister',
            'dispenserStatusRegister', 'yearRegister', 'monthRegister', 'dayRegister', 'dispenserStatusErrorRegister'];
        let check = true;

        elems.forEach(elem => {
            if (this.state[elem] === '') {
                toast.warning(`${elem} cannot be empty!`);
                check = false;
            }
            if (!check) return;
        });

        return check;
    }

    render() {
        return (
            <tr>
                <td colSpan="4">
                    <div className="devices__detail">
                        <div className="devices__detail__row" style={{borderBottom: 'none'}}>
                            <div style={{display: 'flex', alignItems: 'center'}} className="devices__detail__row-left">
                                <h3 style={{marginRight: '1rem'}}>Device identity</h3>
                                <img className="online-icon" src={ this.props.online ? OnlineIcon : OfflineIcon} alt="React Logo" />
                            </div>
                            <p>device {this.props.online ? "online" : "offline"}</p>
                        </div>

                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Mac address</h4>
                            <p>{this.props.device.macAddress}</p>
                        </div>

                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Latitude</h4>
                            <p>{this.props.device.lat}</p>
                        </div>

                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Longitude</h4>
                            <p>{this.props.device.lng}</p>
                        </div>

                        <div className="devices__detail__row" style={{marginBottom: '2rem'}}>
                            <h4 className="devices__detail__row-left">Last time online</h4>
                            <p>{this.props.formattedTime}</p>
                        </div>

                        <div className="devices__detail__row"  style={{borderBottom: 'none'}}>
                            <div style={{display: 'flex', alignItems: 'center'}} className="devices__detail__row-left">
                                <h3 style={{marginRight: '1rem'}}>Device configuration</h3>
                                <img className="settings-icon" src={SettingsIcon} onClick={this.showEdit} alt="device" style={{marginRight: '2rem'}} />
                                {this.state.showEditDialog ? 
                                    <button className="devices__detail__button-config" onClick={this.updateConfiguration}>
                                        Update
                                    </button>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Server host</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="text" name="serverHost" onChange={this.handleChange} value={this.state.serverHost} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.serverHost}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Modbus host</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="text" name="modbusHost" onChange={this.handleChange} value={this.state.modbusHost} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.modbusHost}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Modbus port</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="modbusPort" onChange={this.handleChange} value={this.state.modbusPort} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.modbusPort}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Room id register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="roomIdRegister" onChange={this.handleChange} value={this.state.roomIdRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.roomIdRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Floor id register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="floorIdRegister" onChange={this.handleChange} value={this.state.floorIdRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.floorIdRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Card type register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="cardTypeRegister" onChange={this.handleChange} value={this.state.cardTypeRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.cardTypeRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Access level register lo</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="accessLevelRegisterLo" onChange={this.handleChange} value={this.state.accessLevelRegisterLo} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.accessLevelRegisterLo}</p>
                            }
                        </div>
                        
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Access level register hi</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="accessLevelRegisterHi" onChange={this.handleChange} value={this.state.accessLevelRegisterHi} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.accessLevelRegisterHi}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Write command register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="writeCommandRegister" onChange={this.handleChange} value={this.state.writeCommandRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.writeCommandRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Dispenser status register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="dispenserStatusRegister" onChange={this.handleChange} value={this.state.dispenserStatusRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.dispenserStatusRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Year register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="yearRegister" onChange={this.handleChange} value={this.state.yearRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.yearRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Month register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="monthRegister" onChange={this.handleChange} value={this.state.monthRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.monthRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row">
                            <h4 className="devices__detail__row-left">Day register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="dayRegister" onChange={this.handleChange} value={this.state.dayRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.dayRegister}</p>
                            }
                        </div>
                        <div className="devices__detail__row" style={{marginBottom: '2rem'}}>
                            <h4 className="devices__detail__row-left">Dispenser status error register</h4>
                            {
                                this.state.showEditDialog ?
                                <input type="number" name="dispenserStatusErrorRegister" onChange={this.handleChange} value={this.state.dispenserStatusErrorRegister} autoComplete="false" />
                                :
                                <p>{this.props.activeConfig.dispenserStatusErrorRegister}</p>
                            }
                        </div>

                        <button className="devices__detail__button" onClick={this.toggleLogs}>
                            {this.state.showLogs ? "Hide logs" : "Show logs"}
                        </button>

                        {this.state.showLogs ?
                            <div className="devices__detail__logs">
                                {this.renderLogs(this.props.device.deviceLogs)}
                            </div>
                        : null}
                    </div>
                </td>
            </tr>
        );
    }

    renderLogs = (logs) => {
        logs.sort(this.compare);
        return logs.map(log => {
            let date = new Date(log.timestamp);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let month = date.getMonth() + 1;
            let d = date.getDate() + "-" + month + "-" + date.getFullYear();
            let formattedTime = d + " "+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            return (
                <div className="devices__detail__row" key={log.id}>
                    <h4 className="devices__detail__row-left-log">{log.type}</h4>
                    <p className="devices__detail__row-left-log-2">{formattedTime}</p>
                    <p>{`${log.message}`}</p>
                </div>
            );
        });
    }
}

export default connect(null, { addNewDeviceConfiguration })(DeviceDetail2);