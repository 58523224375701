import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import HotelSelector from '../../Header/HotelSelector/HotelSelector';
import NewRoomDialog from '../../Room/NewRoomDialog/NewRoomDialog';
import RemoveDialog from '../../Utils/RemoveDialog/RemoveDialog';
import { removeRoom, editRoom, editRoomControllerNumber } from '../../../store/actions/room';
import { getHotelById } from '../../../store/actions/hotel';
import { toast } from 'react-toastify';
import UpdateRoomDialog from '../../Room/UpdateRoomDialog/UpdateRoomDialog';
import CalendarIcon from '../../../assets/svg/calendar-mini.svg';
import UserIcon from '../../../assets/svg/user.svg';
import CorrectIcon from '../../../assets/svg/correct.svg';
import DND from '../../../assets/svg/DND.svg';
import Maid from '../../../assets/svg/sobarica.svg';
import SOS from '../../../assets/svg/SOS.svg';
import Delete from '../../../assets/svg/delete.svg';
import Edit from '../../../assets/svg/edit.svg';

const INIT_STATE = {
    showAdvanced: false,
    showNewRoomDialog: false,
    showRemoveDialog: false,
    showEditRoomDialog: false,
    selectedFloor: null,
    selectedRoom: null,
    title: ''
}

class AccommodationOverview extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    componentDidMount() {
        this.props.getHotelById(this.props.match.params.id);
        this._isMounted = true;
        this.myInterval = setInterval(() => {
            this.props.getHotelById(this.props.match.params.id);
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
        this._isMounted = false;
    }

    onShowNewRoomDialog = floor => {
        this.setState({
            showNewRoomDialog: true,
            selectedFloor: floor
        });
    }

    onCloseNewRoomDialog = () => {
        this.setState({
            showNewRoomDialog: false,
            selectedFloor: null
        });
    }

    onShowRemoveDialog = (event, selectedFloor, selectedRoom) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            showRemoveDialog: true,
            title: `Remove room ${selectedRoom.name}`,
            selectedFloor,
            selectedRoom
        });
    }

    onCloseRemoveDialog = () => {
        this.setState({
            showRemoveDialog: false,
            title: '',
            selectedFloor: null,
            selectedRoom: null
        });
    }

    onRemoveRoom = () => {
        if (this.state.selectedFloor && this.state.selectedRoom) {
            this.props.removeRoom(this.state.selectedFloor.id, this.state.selectedRoom.id);
        }
    }

    roomClicked = id => {
        if (!this.props.hotel) return;
        if (this.props.hotel.accessLevels.length === 0 || this.props.hotel.cardTypes.length === 0) {
            toast.warning('Add at least 1 access level and card type to selected hotel');
            return;
        }
        history.push(`/room/${id}`);
    }

    onShowEditRoomDialog = (event, selectedRoom) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            showEditRoomDialog: true,
            title: `Edit room ${selectedRoom.name}`,
            selectedRoom
        });
    }

    onCloseEditRoomDialog = () => {
        this.setState({
            showEditRoomDialog: false,
            title: '',
            selectedRoom: null
        });
    }

    onSubmitEditRoom = (givenId, controllerNumber) => {
        console.log(controllerNumber);
        if(controllerNumber !== undefined && controllerNumber !== ''){
            this.props.editRoomControllerNumber({
                id: this.state.selectedRoom.id,
                controllerNumber: controllerNumber
            })
        }
        if(givenId !== undefined && givenId !== ''){
            this.props.editRoom({
                id: this.state.selectedRoom.id,
                idGiven: givenId
            });
        }
    }
    

    handleChange = ({target}) => {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    render() {
        return (
            <Fragment>
                
                <NewRoomDialog
                    show={this.state.showNewRoomDialog}
                    floor={this.state.selectedFloor}
                    onClose={this.onCloseNewRoomDialog}
                />
                
                <RemoveDialog
                    show={this.state.showRemoveDialog}
                    title={this.state.title} 
                    onClose={this.onCloseRemoveDialog}
                    onRemove={this.onRemoveRoom}
                />

                <UpdateRoomDialog
                    show={this.state.showEditRoomDialog}
                    title={this.state.title}
                    selectedItem={this.state.selectedRoom}
                    onClose={this.onCloseEditRoomDialog}
                    onUpdate={this.onSubmitEditRoom}
                />

                <HotelSelector />

                <div className="accommodation">
                    <div className="accommodation__header">
                        <h3>Rooms</h3>
                        {this.props.user && this.props.user.authority !== 'STAFF' ?
                            <div className="accommodation__filter" onClick={() => {this.setState({showAdvanced: !this.state.showAdvanced})}}>
                                <h5>Show advaced:</h5>
                                <input type="checkbox" name="showAdvanced" onChange={this.handleChange} checked={this.state.showAdvanced} className="accommodation__header-checkbox" />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="accommodation__scroll">
                        {this.renderFloors()}
                    </div>
                </div>
            </Fragment>
        )
    }

    renderRooms = floor => {
        var rooms = floor.rooms.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
        rooms = rooms.map(room => {
            const available = this.isAvailableAtTheMoment(room);
            return {
                ...room,
                ...available
            }
        })
        const roomComponents = rooms.map((room, index) => {
            let reservationLengthText = '';
            if (room.reservations) {
                reservationLengthText = room.reservations.length;
                room.reservations.length === 1 ? reservationLengthText += ' res' : reservationLengthText += ' res';
            }
            let className = "accommodation__room";
            let classNameFooter = "accommodation__room__footer";
            if(room.roomStatus === "DND" || room.roomStatus === "SOS"){
                className = "accommodation__room-busy";
                classNameFooter = "accommodation__room-busy__footer";
            }if(room.roomStatus === "MAID_IN_RROM"){
                className = "accommodation__room-maid";
                classNameFooter = "accommodation__room-maid__footer";
            }if(room.roomStatus === "GUEST_IN_ROOM"){
                className = "accommodation__room-maid";
                classNameFooter = "accommodation__room-maid__footer";
            }

            return (
                <div className={className} key={'room' + room.id} onClick={() => this.roomClicked(room.id)}>
                    {
                        room.available ?
                        <div className="accommodation__room__green-part">
                            <div className="accommodation__room__heading">
                                <p className="accommodation__room__header">{`Room ${room.name}`}</p>
                                {
                                    this.state.showAdvanced ?
                                    <div className="accommodation__room__heading-right-part">
                                        <img src={Edit} alt="correct" 
                                            className="accommodation__room__heading-right-part-edit" 
                                            onClick={event => this.onShowEditRoomDialog(event, room)}
                                        />
                                        <img src={Delete} alt="correct"
                                            onClick={event => this.onShowRemoveDialog(event, floor, room)}
                                            className="accommodation__room__heading-right-part-delete" 
                                        />                                    
                                    </div>  
                                    :
                                    null
                                }                               
                            </div>
                            <div className="accommodation__room__content">
                                <img src={CorrectIcon} alt="correct" className="accommodation__room__content-img" />
                                <p>{room.text}</p>
                            </div>
                        </div>
                        :
                        <div className="accommodation__room__white-part">
                            <div className="accommodation__room__heading">
                                <p className="accommodation__room__header">{`Room ${room.name}`}</p>
                                {
                                    this.state.showAdvanced ?
                                    <div className="accommodation__room__heading-right-part">
                                        <img src={Edit} alt="correct" 
                                            className="accommodation__room__heading-right-part-edit" 
                                            onClick={event => this.onShowEditRoomDialog(event, room)}
                                        />
                                        <img src={Delete} alt="correct"
                                            onClick={event => this.onShowRemoveDialog(event, floor, room)}
                                            className="accommodation__room__heading-right-part-delete" 
                                        />                                    
                                    </div>  
                                    :
                                    null
                                }                              
                            </div>
                            <div className="accommodation__room__content">
                                <img src={UserIcon} alt="user" className="accommodation__room__content-img" />
                                <p>{room.text}</p>
                            </div>
                        </div>
                    }
                    
                    <div className={classNameFooter}>
                        <div className="accommodation__room__footer-days">
                            <img className="accommodation__room__footer-calendar" src={CalendarIcon} alt="calendar" />
                            <p className="accommodation__room__footer-days">{reservationLengthText}</p>
                        </div>

                        <div className="accommodation__room__footer-right-part">
                            {
                                this.renderFooter(room)
                            }
                        </div>
                    </div>
                </div>
            );
        });

        return roomComponents;
    }

    renderFooter = room => {
        switch(room.roomStatus){
            case "DND":
                return(
                    <Fragment>
                        <p className="accommodation__room-busy__footer-dnd-text">Do not disturb</p>
                        <img className="accommodation__room-busy__footer-dnd" src={DND} alt="calendar" />
                    </Fragment>
                )
            case "MAID_IN_RROM":
                return(
                    <Fragment>
                        <p className="accommodation__room-maid__footer-maid-text">Maid in room</p>
                        <img className="accommodation__room-maid__footer-maid" src={Maid} alt="calendar" />
                    </Fragment>
                )
            case "SOS":
                return(
                    <Fragment>
                        <p className="accommodation__room-busy__footer-sos-text">Sos</p>
                        <img className="accommodation__room-busy__footer-sos" src={SOS} alt="calendar" />
                    </Fragment>
                )    
            case "GUEST_IN_ROOM":
                return(
                    <Fragment>
                        <p className="accommodation__room-maid__footer-maid-text">Guest in room</p>
                    </Fragment>
                )    
            default:
                return null;    
        }
    }

    renderFloors = () => {
        if (!this.props.hotel) return null;
        const floors = this.props.hotel.floors.sort((a,b) => (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0)); 
        return floors.map(floor => {
            //floor with null level does not show in accommodation overview page
            if(floor.level === null) {
                return null;
            }
            else return (
                <div className="accommodation__floor" key={'floor' + floor.id}>
                    <div className="accommodation__floor__header">
                        <p className="accommodation__floor__title">{`Floor ${floor.name}`}</p>
                        {
                            this.state.showAdvanced && this.props.user && this.props.user.authority !== 'STAFF' ?
                            <button className="accommodation-button" onClick={() => this.onShowNewRoomDialog(floor)}>
                                <p>Add room</p>
                            </button>
                            : null
                        }
                    </div>
                    <div className="accommodation__floor__rooms">
                        {this.renderRooms(floor)}
                    </div>
                </div> 
            );
        });
    }

    isAvailableAtTheMoment = room => {
        const reservations = room.reservations;
        var available = true;
        var text = 'Available';

        reservations.forEach(reservation => {
            const startDate = new Date(reservation.startDate);
            const endDate = new Date(reservation.endDate);
            const now = new Date();
            if (now > startDate && now < endDate) {
                available = false;
                text = reservation.fullName;
            }
        });
        return { available, text }
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel.selectedHotel,
    hotels: state.hotel.hotels,
    user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
    removeRoom: (floorId, roomId) => dispatch(removeRoom(floorId, roomId)),
    getHotelById: id => dispatch(getHotelById(id)),
    editRoom: room => dispatch(editRoom(room)),
    editRoomControllerNumber: room => dispatch(editRoomControllerNumber(room))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOverview);