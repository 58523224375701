import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Spinner from '../../Utils/Spinner/Spinner';
import { connect } from 'react-redux';
import { feedbackResetStatus } from '../../../store/actions/feedback';
import CloseIcon from '../../../assets/svg/close.svg';

Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

class RemoveDialog extends Component {

    onSubmit = event => {
        event.preventDefault();
        this.props.onRemove();
    }

    componentDidUpdate() {
        if (this.props.feedbackInfo.isSuccessful === true && this.props.show) {
            toast.success(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
            this.props.onClose();
        } else if(this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    render() {
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.show}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.onClose}
                    style={ modalStyle }
                >
                    <div className="new-room-modal">
                        <div className="new-room-modal__header">
                            <h3>{this.props.title || 'Remove'}</h3>
                            <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={this.props.onClose} />
                        </div>
                        <div className="new-room-modal__content">
                            <p className="new-room-modal__content-title">{`Are you sure you want to remove?`}</p>
                        </div>
                        <div className="new-room-modal__footer">
                            <button className="new-room-modal__footer-btn-remove" onClick={this.onSubmit}>
                                <div>
                                    <p>Remove</p>
                                    {
                                        this.props.feedbackInfo.isFetching ?
                                        <Spinner />
                                        : null
                                    }
                                </div>
                            </button>
                            <button className="new-room-modal__footer-cancel" onClick={this.props.onClose}>
                                <p>Cancel</p>
                            </button>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    feedbackInfo: state.feedbackInfo
});

const mapDispatchToProps = dispatch => ({
    feedbackResetStatus: () => dispatch(feedbackResetStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDialog);