import { FETCH_ACCEPTED_DEVICES, FETCH_PENGING_DEVICES, DEVICE_ACCEPTED, NEW_DEVICE_REGISTRATION, DEVICE_HEARTBEAT, DEVICE_REJECTED, FETCH_REJECTED_DEVICES, NEW_DEVICE_CONFIGURATION, RESET_DEVICE_UPDATED} from '../constants';

const INITIAL_STATE = {
    acceptedDevices: [],
    pendingDevices: [],
    rejectedDevices: [],
    deviceConfigUpdated: null
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_ACCEPTED_DEVICES:
            return { ...state, acceptedDevices: action.payload };
        case FETCH_PENGING_DEVICES:
            return { ...state, pendingDevices: action.payload };
        case FETCH_REJECTED_DEVICES:
            return { ...state, rejectedDevices: action.payload }
        case DEVICE_ACCEPTED:
           return { ...state, pendingDevices: state.pendingDevices.filter(device => device.id !== action.payload) };
        case NEW_DEVICE_REGISTRATION:
            return { ...state, pendingDevices: [...state.pendingDevices, action.payload] };
        case DEVICE_HEARTBEAT:
            let timestamp = Math.round((new Date()).getTime() / 1000);
            return {
                ...state,
                acceptedDevices: state.acceptedDevices.map(device => device.id === action.payload.id ? 
                    {
                        ...device, 
                        lastTimeOnline: action.payload.lastTimeOnline,
                        secondsPassed: timestamp - action.payload.lastTimeOnline,
                        deviceLogs: action.payload.deviceLogs  

                    } 
                    : device)
            }
        case DEVICE_REJECTED:
            return { ...state, pendingDevices: state.pendingDevices.filter(device => device.id !== action.payload) } 
        case NEW_DEVICE_CONFIGURATION:
            return {
                ...state,
                deviceConfigUpdated: true,
                acceptedDevices: state.acceptedDevices.map(device => device.id === action.payload.id ?
                    {
                        ...device,
                        configurations: action.payload.configurations
                    }
                    :
                        device
                    ),
                updatedDevice: action.payload   

            }
        case RESET_DEVICE_UPDATED:
            return{
                ...state,
                deviceConfigUpdated: null
            }    

        default:
            return state;
    }
}