import React, { Component } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Spinner from '../../Utils/Spinner/Spinner';
import { addNewRoom } from '../../../store/actions/room';
import { feedbackResetStatus } from '../../../store/actions/feedback';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/svg/close.svg';

Modal.setAppElement('#root');

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 5
    },
    content: {
        top                   : '10%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0%)',
        width: '40rem',
        borderRadius: '1.5rem',
        padding: 0
    }
}

const INIT_STATE = {
    roomName: ''
}

class NewRoomDialog extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = event => {
        event.preventDefault();
        const validation = this.validate();
        if (validation && this.props.hotel && this.props.floor) {
            const newRoom = {
                accommodationId: this.props.hotel.id,
                floorId: this.props.floor.id,
                name: this.state.roomName
            }
            this.setState(INIT_STATE, () => {
                this.props.addNewRoom(newRoom);
            });
        }
    }

    validate = () => {
        if (this.state.roomName === '') {
            toast.warning('Name cannot be empty!');
            return false;
        }
        return true;
    }

    componentDidUpdate() {
        if (this.props.feedbackInfo.isSuccessful === true && this.props.show) {
            toast.success(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
            this.props.onClose();
        } else if(this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.props.onClose}
                style={modalStyle}
            >
                <div className="new-room-modal">
                    <div className="new-room-modal__header">
                        <h3>{`Add new room (${this.props.floor && 'Floor ' + this.props.floor.name})`}</h3>
                        <img src={CloseIcon} style={{width: '2.2rem', height: '2.2rem'}} alt="close-btn" onClick={this.props.onClose} />
                    </div>
                    <div className="new-room-modal__content">
                        <div className="new-room-modal__content__input">
                            <div className="new-room-modal__content__input-title">
                                <p>Name: </p>
                            </div>
                            <input type="text" name="roomName" onChange={this.handleChange} value={this.state.roomName} autoComplete="false" />
                        </div>
                    </div>
                    <div className="new-room-modal__footer">
                    <button className="new-room-modal__footer-btn" onClick={this.onSubmit}>
                        <div>
                            <p>Add</p>
                            {
                                this.props.feedbackInfo.isFetching ?
                                <Spinner />
                                : null
                            }
                        </div>
                    </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel.selectedHotel,
    feedbackInfo: state.feedbackInfo
})

const mapDispatchToProps = dispatch => ({
    addNewRoom: newRoom => dispatch(addNewRoom(newRoom)),
    feedbackResetStatus: () => dispatch(feedbackResetStatus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewRoomDialog);