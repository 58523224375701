import React, { Component, Fragment } from 'react';
import Spinner from '../Utils/Spinner/Spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { getAllUsers, deleteUser} from '../../store/actions/auth';
import { feedbackResetStatus } from '../../store/actions/feedback';
import AddUserModal from './AddUserModal/AddUserModal';
import Delete from '../../assets/svg/delete.svg';
import Avatar from 'react-avatar';
import RemoveDialog from '../Utils/RemoveDialog/RemoveDialog';



const INIT_STATE = {
    showAddDialog: false,
    username: "",
    email: "",
    title: "",
    type: "OWNER",
    showRemoveDialog: false,
    selectedUser: null
}

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    openAddDialog = title => {
        this.setState({
            showAddDialog: true,
            title,
            username: '',
            email: ""
        });
    }

    closeAddDialog = () => {
        this.setState({
            showAddDialog: false,
            title: '',
            username: '',
            email: ""
        })
    }

    onShowRemoveDialog = (event, user) =>{
        this.setState({
            showRemoveDialog: true,
            title: `Remove user ${user.username}`,
            selectedUser: user
        })
    }

    onCloseRemoveDialog = ()=>{
        this.setState({
            showRemoveDialog: false,
            title: '',
            selectedUser: null
        });
    }

    onRemoveUser = () => {
        this.props.deleteUser(this.state.selectedUser);

    }

    componentDidMount(){
        this.props.getAllUsers();
    }

    componentDidUpdate(){
        if (this.props.feedbackInfo.isSuccessful === true) {
            this.setState({
                showAddDialog: false
            })
            toast.success(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }else if(this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    renderUsers = () => {
        if (!this.props.allUsers) return null;
        return this.props.allUsers.map(user => {
            return (
                <div className="users__user" key={user.username}>
                    <div className="users__user-content">
                        <div className="users__user-content-left-part">
                            <Avatar size="50" className="users__user-content-avatar" color={Avatar.getRandomColor('sitebase', ['#4c66b0', '#e34043', '#4c66b0'])} name={user.email} round={true} />
                            <div>
                            <div className="users__user-content-left-part-data">
                                <p className="users__user-content-left-part-data-email">{user.email}</p>
                                <p className="users__user-content-left-part-data-username">{user.username}</p>
                            </div>
                                <p className="users__user-content-left-part-data-type">{user.type}</p>
                            </div>
                        </div>
                        <div className="users__user-content-right-part">
                            <img src={Delete} alt="correct"
                                onClick={event => this.onShowRemoveDialog(event, user)}
                                className="users__user-content-right-part-delete" 
                            />
                        </div>
                    </div>
                </div>
            )
        });
    }

    render() {
        return (
            <Fragment>
                <AddUserModal
                    showAddDialog={this.state.showAddDialog}
                    closeAddDialog={this.closeAddDialog}
                    title="Add new user"
                />
                <RemoveDialog
                    show={this.state.showRemoveDialog}
                    title={this.state.title} 
                    onClose={this.onCloseRemoveDialog}
                    onRemove={this.onRemoveUser}
                />
                <div className="users">
                    <div className="users__header">
                        <h2>Users</h2>
                        <button className="users-button" onClick={() => this.openAddDialog("Add new user")}>
                            <p>Add user</p>
                            {
                                this.props.feedbackInfo.isFetching === true ? 
                                <Spinner />
                                :
                                null
                            }
                        </button>
                    </div>
                    <div className="users__scroll">
                        <div className="users__users-list">
                            {this.renderUsers()}
                        </div>
                    
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    allUsers: state.auth.allUsers,
    feedbackInfo: state.feedbackInfo
});

const mapDispatchToProps = dispatch => ({
    getAllUsers: () => dispatch(getAllUsers()),
    feedbackResetStatus: () => dispatch(feedbackResetStatus()),
    deleteUser: (user) => dispatch(deleteUser(user))
});

export default connect(mapStateToProps,mapDispatchToProps)(Users);