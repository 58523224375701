export const BASE_URL = 'https://smarthotel.rs';
// export const BASE_URL = "http://localhost:8080";

// auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const USERS_DATA = "USERS_DATA";
export const ALL_USER = "ALL_USERS";
export const USER_ADDED = "USER_ADDEDS";
export const USER_DELTED = "USER_DELETED";

// feedback
export const FEEDBACK_SUCCESSFUL = "FEEDBACK_SUCCESSFUL";
export const FEEDBACK_FAILED = "FEEDBACK_FAILED";
export const FEEDBACK_IS_FETCHING = "FEEDBACK_IS_FETCHING";
export const FEEDBACK_RESET_STATUS = "FEEDBACK_RESET_STATUS";

// hotels
export const HOTELS = "HOTELS";
export const GET_HOTEL = "GET_HOTEL";
export const ADD_NEW_HOTEL = "ADD_NEW_HOTEL";
export const STAFF_ADDED = "STAFF_ADDED";
export const STAFF_REMOVED = "STAFF_REMOVED";
export const LOGO_FETCHED = "LOGO_FETCHED";
export const LOGO_UPLOADED = "LOGO_UPLOADED";
export const HOTEL_UPDATED = "HOTEL_UPDATED";

// rooms
export const GET_ROOM = "GET_ROOM";
export const SET_ROOM_TO_NULL = "SET_ROOM_TO_NULL";

// Reservations
export const POST_RESERVATION = "POST_RESERVATION";
export const DECLINE_RESERVATION = "DECLINE_RESERVATION";
export const PIN_UNLOCKED = "PIN_UNLOCKED";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";

// Floors
export const ADD_NEW_FLOOR = "ADD_NEW_FLOOR";
export const REMOVE_FLOOR = "REMOVE_FLOOR";
export const EDIT_FLOOR = "EDIT_FLOOR";

// Access level
export const ADD_NEW_ACCESS_LEVEL = "ADD_NEW_ACCESS_LEVEL";
export const REMOVE_ACCESS_LEVEL = "REMOVE_ACCESS_LEVEL";
export const EDIT_ACCESS_LEVEL = "EDIT_ACCESS_LEVEL";

// Card type
export const ADD_NEW_CARD_TYPE = "ADD_NEW_CARD_TYPE";
export const REMOVE_CARD_TYPE = "REMOVE_CARD_TYPE";
export const EDIT_CARD_TYPE = "EDIT_CARD_TYPE";

// Rooms
export const ADD_NEW_ROOM = "ADD_NEW_ROOM";
export const REMOVE_ROOM = "REMOVE_ROOM";
export const EDIT_ROOM = "EDIT_ROOM";
export const EDIT_CONTROLLER_NUMBER = "EDIT_CONTROLLER_NUMBER";

// Devices
export const FETCH_ACCEPTED_DEVICES = "FETCH_ACCEPTED_DEVICES";
export const FETCH_PENGING_DEVICES = "FETCH_PENGING_DEVICES";
export const DEVICE_ACCEPTED = "DEVICE_ACCEPTED";
export const NEW_DEVICE_CONFIGURATION = "NEW_DEVICE_CONFIGURATION";
export const NEW_DEVICE_REGISTRATION = "NEW_DEVICE_REGISTRATION";
export const DEVICE_HEARTBEAT = "DEVICE_HEARTBEAT";
export const DEVICE_REJECTED = "DEVICE_REJECTED";
export const FETCH_REJECTED_DEVICES = "FETCH_REJECTED_DEVICES";
export const RESET_DEVICE_UPDATED = "RESET_DEVICE_UPDATED";
