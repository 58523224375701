import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePassword, getUserInfo } from '../../store/actions/auth';
import { feedbackResetStatus } from '../../store/actions/feedback';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';
import HotelLogo from '../../assets/svg/hotel.svg';
import EmailIcon from '../../assets/svg/email.svg';
import PhoneIcon from '../../assets/svg/phone.svg';
import WorldIcon from '../../assets/svg/world.svg';
import LocationIcon from '../../assets/svg/location.svg';
import history from '../../history';


const INIT_STATE = {
    newPassword: null,
    confirmPassword: null,
    changePassword: false,
    changingPassword: false,
    changingConfirmPassword: false,
    hintClassName: null,
    passwordStrenght: null,
    passwordMatching: null
}

class Account extends Component {

    componentDidMount(){
        this.props.getUserInfo();
    }

    constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    componentDidUpdate() {
        if (this.props.feedbackInfo.isSuccessful === true) {
            toast.success(this.props.feedbackInfo.message);
            this.setState(INIT_STATE);
            this.props.feedbackResetStatus();
        } else if (this.props.feedbackInfo.isSuccessful === false) {
            toast.error(this.props.feedbackInfo.message);
            this.props.feedbackResetStatus();
        }
    }

    onPasswordChange = (value) => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        this.setState({
            changingPassword: true,
            newPassword: value
        })

        if(strongRegex.test(value)) {
            this.setState({
                hintClassName: "strongHint",
                passwordStrenght: "Strong password"
            })
        } else if(mediumRegex.test(value)) {
            this.setState({
                hintClassName: "mediumHint",
                passwordStrenght: "Medium password"
            })
        } else {
            this.setState({
                hintClassName: "weakHint",
                passwordStrenght: "Weak password"

            })
        }
    }

    onConfirmPasswordChange = value => {
        this.setState({
            changingConfirmPassword: true
        })
        if(this.state.newPassword === value){
            this.setState({
                passwordMatching: true
            })
        }else{
            this.setState({
                passwordMatching: false
            })
        }
    }

    onChangePasswordSumbit = () => {
        const newPassword = this.state.newPassword;
        this.props.changePassword(newPassword);
    }

    renderChangePasswordForm = () => {
        return (
            <div className="account__config__content">
                <div className="account__config__content__input">
                    <div className="account__config__content__input-title">
                        <p>New password: </p>
                    </div>
                    <input type="password" name="newPassword" onChange={e => this.onPasswordChange(e.target.value)} value={this.state.name} autoComplete="false" />
                    {
                        this.state.changingPassword === true ? 
                        <p className={this.state.hintClassName}>{this.state.passwordStrenght}</p>
                        :
                        null
                    }
                </div>
                <div className="account__config__content__input">
                    <div className="account__config__content__input-title">
                        <p>Confirm new password: </p>
                    </div>
                    <input type="password" name="newPasswordConfirm" onChange={e => this.onConfirmPasswordChange(e.target.value)} value={this.state.name} autoComplete="false" />
                    {
                        this.state.changingConfirmPassword === true ? 
                        <p>{this.state.passwordMatching !== true ? "Passwords not matching" : null}</p>
                        :
                        null
                    }
                </div>
                <div className="account__config__content__input">
                    <button className={ this.state.passwordMatching !== true ? "account__config__content__input__change-password-submit-button-disabled" : "account__config__content__input__change-password-submit-button"} onClick={() => {this.onChangePasswordSumbit()}}>
                        <div>
                            <p>Submit</p>
                        </div>
                    </button>
                    <button className="account__config__content__input__change-password-cancel-button"  onClick={(e) => this.setState({changePassword: false, newPassword: null, confirmPassword: null})}>
                        <p>Cancel</p>
                    </button>
                </div>
            </div>
        )
    }

    renderAccountInfo = () => {
        if(this.props.currentUser === undefined){
            return null;
        }
        return(
            <div className="account__config__item-account account__info">
                <Avatar size="200" className="account__config__item-account__avatar" 
                                    color={Avatar.getRandomColor('sitebase', ['#d14d4d', '#d14d4d', '#d14d4d'])} 
                                    name={this.props.currentUser.username} round={true} />
                <h2>{this.props.currentUser.email}</h2>
                <p className="account__config__item-account__username">
                    {this.props.currentUser.username}
                </p>
                <br />
                {
                    this.state.changePassword === true ? 
                    null
                    :
                    <button 
                        className="account__config__item-account__change-password-button" 
                        type="submit" 
                        onClick={() => this.setState({changePassword: !this.state.changePassword})}
                    >
                        Change password
                    </button>
                }
                {
                    this.state.changePassword === true ? 
                    this.renderChangePasswordForm()
                    :
                    null
                }
            </div>
        )
    }

    onHotelLink = (hotel) => {
        history.push(`/accommodation/overview/${hotel.id}`);
    }

    renderHotels = () => {
        if(!this.props.hotels){return null}
        return this.props.hotels.map((hotel,index) => {
            let className = index % 2 === 0 ? "account__config__hotel__item user__hotel-left" : "account__config__hotel__item user__hotel-right";
            return(
                <div className={className} key={hotel.id}>
                    <div className="account__config__content__header">
                        <img src={HotelLogo} 
                            className="account__config__content-hotel-logo" 
                            alt="logo"/>
                        <p className="account__config__content-hotel-name"onClick={()=>this.onHotelLink(hotel)}>{hotel.name}</p>
                    </div>
                    <div className="account__config__content__info-content">
                    <div className="account__config__content__info">
                        <img src={LocationIcon} className="account__config__content-hotel-logo" alt="location"/>
                        <small className="account__config__content-hotel-info">{hotel.address + ', ' + hotel.city}</small>
                    </div>
                    <div className="account__config__content__info">
                        <img src={WorldIcon} className="account__config__content-hotel-logo" alt="world"/>
                        <small className="account__config__content-hotel-info">{hotel.city + ', ' + hotel.country}</small>
                    </div>
                    <div className="account__config__content__info">
                        <img src={PhoneIcon} className="account__config__content-hotel-logo" alt="phone"/>
                        <small className="account__config__content-hotel-info">{hotel.phoneNumber}</small>
                    </div>
                    <div className="account__config__content__info">
                        <img src={EmailIcon} className="account__config__content-hotel-logo" alt="email"/>
                        <small className="account__config__content-hotel-info">{hotel.email}</small>
                    </div>
                    </div>
                </div>
            )
        })
    }


    render() {
        return (
            <div className="account__config">
                    { 
                        this.renderAccountInfo()
                    }

                    {
                        this.renderHotels()
                    }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
    feedbackInfo: state.feedbackInfo,
    hotels: state.hotel.hotels
});

const mapDispatchToProps = dispatch => ({
    changePassword: (obj) => dispatch(changePassword(obj)),
    getUserInfo: () => dispatch(getUserInfo()),
    feedbackResetStatus: () => dispatch(feedbackResetStatus())
});


export default connect(mapStateToProps, mapDispatchToProps)(Account);