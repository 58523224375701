import { FETCH_ACCEPTED_DEVICES, FETCH_PENGING_DEVICES, DEVICE_ACCEPTED, NEW_DEVICE_CONFIGURATION, NEW_DEVICE_REGISTRATION, DEVICE_HEARTBEAT, DEVICE_REJECTED, FETCH_REJECTED_DEVICES, RESET_DEVICE_UPDATED} from '../constants';
import apiBase from '../../apis/api';
import { makeHeader } from './base';

export const fetchAcceptedDevices = () => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get('/devices/accepted', headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: FETCH_ACCEPTED_DEVICES, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const fetchPendingDevices = () => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get('/devices/pending', headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: FETCH_PENGING_DEVICES, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const fetchRejectedDevices = () => {
    return async dispatch => {
        try {
            const headers = makeHeader();
            const response = await apiBase.get('/devices/rejected', headers);
            if (response.status !== 200) throw new Error();
            dispatch({ type: FETCH_REJECTED_DEVICES, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const acceptDevice = obj => {
    return async dispatch => {
        try { 
            const headers = makeHeader();
            const response = await apiBase.put(`/devices/${obj.deviceId}/accomodation/${obj.accomodationId}`, obj.body, headers);
            if (response.status !== 200) throw new Error();
            dispatch({type: DEVICE_ACCEPTED, payload: obj.deviceId });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const addNewDeviceConfiguration = obj => {
    return async dispatch => {
        try { 
            const headers = makeHeader();
            const response = await apiBase.post(`/devices/${obj.deviceId}/configurations`, obj.body, headers);
            if (response.status !== 200) throw new Error();
            dispatch({type: NEW_DEVICE_CONFIGURATION, payload: response.data });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const rejectDevice = device => {
    return async dispatch => {
        try { 
            const headers = makeHeader();
            const response = await apiBase.put(`/devices/reject/${device.id}`, device, headers);
            if (response.status !== 200) throw new Error();
            dispatch({type: DEVICE_REJECTED, payload: device.id });
        } catch (e) {
            console.log(e.message);
        }
    }
}

export const newPendingDevicesRegistered = device => {
    return {type: NEW_DEVICE_REGISTRATION, payload: device };
}

export const deviceHeartbeat = device => {
    return {type: DEVICE_HEARTBEAT, payload: device}
}

export const resetUpdatedDevice = () => {
    return {type: RESET_DEVICE_UPDATED }
}